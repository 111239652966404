import "./List.css";
import { Words_localMemory } from "../../../../3_LocalMemory/Words_localMemory";
import { useMediaQuery } from 'react-responsive';
import Card from "../Card/Card";
import { observer } from "mobx-react";
import { useEffect, RefObject, useRef, useState } from "react";
import Add from "../Add/Add";
import { Preferences_localMemory } from "../../../../3_LocalMemory/Preferences_localMemory";
import RowInTable from "../RowInTable/RowInTable";
import imageAndAudio_localMemory from "../../../../3_LocalMemory/imageAndAudio_localMemory";
import useWindowDimensions from "../../../../5_Helpers/UseWindowDimensions";

interface ListProps {
    words_localMemory: Words_localMemory;
    preferences_localMemory: Preferences_localMemory;
    userUuid: string;
    heightOfContent: number;
}

function List(props: ListProps): JSX.Element {
    const words = props.words_localMemory.words;
    const userUuid = props.userUuid;

    // Display Mode ---------------------------------------------------------------------------
    // npm install react-responsive
    // https://chat.openai.com/c/180efffd-535b-4244-a155-5b6dccbfed4a
    let isMobile = useMediaQuery({ maxWidth: 895 });
    const [cardDisplay, setCardDisplay] = useState<boolean>(false);
    const [localStorageLoaded, setLocalStorageLoaded] = useState<boolean>(false);
    const buttonCardClasses = cardDisplay ? 'clicked-button' : 'unClicked-button';
    const buttonTableClasses = cardDisplay ? 'unClicked-button' : 'clicked-button';

    function setCardDisplayHandler(): void {
        setCardDisplay(!cardDisplay);
        // localStorage
        localStorage.setItem("cardMode", (!cardDisplay).toString());
    }

    // localStorage
    useEffect(() => {
        setCardDisplay(localStorage.getItem("cardMode") === 'true');
        setLocalStorageLoaded(true);
    }, []);

    // Audio Play Handler ---------------------------------------------------------------------
    const audioRef: RefObject<HTMLAudioElement> = useRef();
    const [src, setSrc] = useState<string>("");
    const [currentAudioName, setCurrentAudioName] = useState<string>("");
    const rateSpeech = props.preferences_localMemory.preferences?.rateSpeech;

    async function audioPlayHandler(audioName: string): Promise<void> {
        if (currentAudioName === audioName) {
            audioRef.current.load();
            audioRef.current.playbackRate = rateSpeech;
            audioRef.current.play();
        }
        else {
            let newSrc = await imageAndAudio_localMemory.getAudio(userUuid, audioName);
            setCurrentAudioName(audioName);
            setSrc(newSrc);
        }
    }

    // https://stackoverflow.com/questions/56247433/how-to-use-setstate-callback-on-react-hooks
    useEffect(() => {
        if (audioRef.current && src) {
            audioRef.current.load();
            audioRef.current.playbackRate = rateSpeech;
            audioRef.current.play();
        }
    }, [src]);

    // Hide Translation -------------------------------------------------------------------------
    const [hideTranslation, setHideTranslation] = useState<boolean>(false);
    function toggleHideTranslation(): void { setHideTranslation(!hideTranslation); }

    // -------------------------------------------------------------------------------------------
    let heightOfSettings = 33.5;
    const { width: widthOfWindow } = useWindowDimensions();

    // ------------------------------------------------------------------------------------------
    return (
        <div className="List">

            {/* Audio ----------------------------------------------------------------------------- */}
            <audio src={src} ref={audioRef} />

            {/* Settings ----------------------------------------------------------------------------- */}
            <div id="settings">
                {/* Add */}
                <Add words_localMemory={props.words_localMemory} preferences_localMemory={props.preferences_localMemory} />
                {/* Hide Translation */}
                {hideTranslation ?
                    <i className="fa-solid fa-eye-slash" onClick={toggleHideTranslation}></i>
                    :
                    <i className="fa-solid fa-eye" onClick={toggleHideTranslation}></i>
                }
                {/* Display Mode */}
                {!isMobile &&
                    <div onClick={() => setCardDisplayHandler()}>
                        <i className={`fa-solid fa-table ${buttonTableClasses}`}></i>
                        <i className={`fa-solid fa-grip ${buttonCardClasses}`}></i>
                    </div>
                }
            </div>

            {/* Words ----------------------------------------------------------------------------- */}
            {localStorageLoaded &&
                <div id="words" style={{ height: props.heightOfContent - heightOfSettings }}>
                    <div id="startDiv" style={{ width: widthOfWindow - 4 }}></div>
                    {isMobile || cardDisplay ?
                        // Cards -----------------------------------------------------------------------------
                        <div id="cardsDiv">
                            {
                                props.words_localMemory.words?.map(word => word?.display && <Card
                                    key={word.uuid}
                                    word={word}
                                    userUuid={props.userUuid}
                                    audioPlay={audioPlayHandler}
                                    words_localMemory={props.words_localMemory}
                                    preferences_localMemory={props.preferences_localMemory}
                                    hideTranslation={hideTranslation}
                                />)
                            }
                        </div>
                        :
                        // Table -----------------------------------------------------------------------------
                        <div className="Table">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Word</th>
                                        <th>Translation</th>
                                        <th>Picture</th>
                                        <th>Sentence</th>
                                        <th>Translation</th>
                                        <th>Score</th>
                                        <th>Category</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.words_localMemory.words?.map(word => word?.display && <RowInTable
                                            key={word.uuid}
                                            word={word}
                                            userUuid={props.userUuid}
                                            audioPlay={audioPlayHandler}
                                            words_localMemory={props.words_localMemory}
                                            preferences_localMemory={props.preferences_localMemory}
                                            hideTranslation={hideTranslation}
                                        />)
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                    <div id="endDiv" style={{ width: widthOfWindow - 4 }}></div>
                </div>
            }

        </div>
    );
}

export default observer(List);
