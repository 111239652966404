import { Tooltip } from "react-tooltip";
import "./TooltipKeyboardShortcuts.css";
import { useState } from "react";
import LanguageSelectorForTranslation from "../Languages/LanguageSelectorForTranslation/LanguageSelectorForTranslation";
import preferences_localMemory from "../../../3_LocalMemory/Preferences_localMemory";

interface TooltipKeyboardShortcutsProps {
    mode: string;
}

function TooltipKeyboardShortcuts(props: TooltipKeyboardShortcutsProps): JSX.Element {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    return (
        <div className="TooltipKeyboardShortcuts">

            <i
                className="fa-solid fa-circle-exclamation"
                data-tooltip-id="tipTooltip"
                onClick={() => setIsTooltipOpen(!isTooltipOpen)}
                style={{ color: isTooltipOpen ? 'white' : '' }}
            ></i>

            {isTooltipOpen &&
                <Tooltip
                    id="tipTooltip"
                    opacity='1'
                    clickable={true}
                    isOpen={isTooltipOpen}
                >
                    {(() => {
                        switch (props.mode) {
                            case 'mediaPlayer':
                                return <div id='container'>
                                    <h3>Touch Gestures and Keyboard Shortcuts</h3>
                                    <div>
                                        <div className="row header">
                                            <div className="gesture">Touch Gestures</div>
                                            <div className="keys">Keyboard Shortcuts</div>
                                            <div className="result">Result</div>
                                        </div>
                                        <div>
                                            <div className="gesture">Double tap</div>
                                            <div className="keys">Space</div>
                                            <div className="result">Stop/Continue</div>
                                        </div>
                                        <div>
                                            <div className="gesture">Tap</div>
                                            <div className="keys">Enter</div>
                                            <div className="result">Reveal/hide the answer</div>
                                        </div>
                                        <div>
                                            <div className="gesture">Swipe up/down</div>
                                            <div className="keys">up/down arrow</div>
                                            <div className="result">Increase/decrease the score</div>
                                        </div>
                                        <div>
                                            <div className="gesture">Swipe right/left</div>
                                            <div className="keys">right/left arrow</div>
                                            <div className="result">Move to the next/previous word</div>
                                        </div>
                                    </div>

                                    <LanguageSelectorForTranslation preferences_localMemory={preferences_localMemory} />

                                    <span id="guide">
                                        <h3>Allow background activity</h3>
                                        Make sure background activity is enabled on your mobile phone.&nbsp;
                                        <a href="https://www.guidingtech.com/fixes-music-stops-playing-samsung-when-phone-locks/" target="_blank">Check out the guide.</a>
                                    </span>
                                </div>
                            case 'test':
                                return <div id='container'>
                                    <h3>Touch Gestures and Keyboard Shortcuts</h3>
                                    <div>
                                        <div className="row header">
                                            <div className="gesture">Touch Gestures</div>
                                            <div className="keys">Keyboard Keys</div>
                                            <div className="result">Result</div>
                                        </div>
                                        <div className="row">
                                            <div className="gesture">Tap</div>
                                            <div className="keys">Space / Enter</div>
                                            <div className="result">Reveal/hide the answer</div>
                                        </div>
                                        <div className="row">
                                            <div className="gesture">Swipe up/down</div>
                                            <div className="keys">up/down arrow</div>
                                            <div className="result">Increase/decrease the score</div>
                                        </div>
                                        <div className="row">
                                            <div className="gesture">Swipe right/left</div>
                                            <div className="keys">right/left arrow</div>
                                            <div className="result">Move to the next/previous word</div>
                                        </div>
                                    </div>
                                    <LanguageSelectorForTranslation preferences_localMemory={preferences_localMemory} />
                                </div>
                        }
                    })()}
                </Tooltip>
            }

        </div>
    );
}

export default TooltipKeyboardShortcuts;
