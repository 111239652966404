import axios from 'axios';
import urls from './Urls';
import CredentialsModel from '../1_Models/CredentialsModel';

class User_webAccess {
    public async updateNameAndPassword(name: string, password?: string): Promise<string> {
        const response = await axios.patch<string>(urls.user.update, { name, password });
        name = response.data;
        return name;
    }

    public async register(email: string): Promise<string> {
        const response = await axios.post<string>(urls.user.register, { email });
        const message = response.data;
        return message;
    }

    public async login(credentials: CredentialsModel): Promise<string> {
        const response = await axios.post<string>(urls.user.login, credentials);
        const token = response.data;
        return token;
    }

    public async passwordReset_stepOne(email: string): Promise<string> {
        const response = await axios.post<string>(urls.user.passwordReset_stepOne, { email });
        const message = response.data;
        return message;
    }

    public async passwordReset_stepTwo(email: string, temporaryPassword: string): Promise<string> {
        const response = await axios.post<string>(urls.user.passwordReset_stepTwo, { email, temporaryPassword });
        const message = response.data;
        return message;
    }

    public async replaceToken(): Promise<string> {
        const response = await axios.get<string>(urls.user.replaceToken);
        const token = response.data;
        return token;
    }
}

const user_webAccess = new User_webAccess();

export default user_webAccess;
