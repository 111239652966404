import "./Add.css";
import { SyntheticEvent, useState } from "react";
import WordModel from "../../../../1_Models/WordModel";
import { Preferences_localMemory } from "../../../../3_LocalMemory/Preferences_localMemory";
import { Words_localMemory } from "../../../../3_LocalMemory/Words_localMemory";
import errorMessages from "../../../../5_Helpers/ErrorMessages";
import notify from "../../../../5_Helpers/NotifyMessages";
import { useForm } from "react-hook-form";
import Overlay from "../../../SharedArea/Overlay/Overlay";
// https://www.npmjs.com/package/react-drag-drop-files
import { FileUploader } from "react-drag-drop-files";
import { observer } from "mobx-react";
import { useMediaQuery } from "react-responsive";
import artificialIntelligence_webAccess from "../../../../2_WebAccess/AI_webAccess";
// https://www.npmjs.com/package/react-tooltip
import { Tooltip } from 'react-tooltip'
import LanguageSelector from "../../../SharedArea/Languages/LanguageSelector/LanguageSelector";
import TooltipSelectedText from "../../../SharedArea/TooltipSelectedText/TooltipSelectedText";
// העתקת מילים של משרד החינוך **************************************************************************************************************************************************************************
import { words_7_array } from "./file copy";
// **************************************************************************************************************************************************************************

interface AddProps {
    words_localMemory: Words_localMemory;
    preferences_localMemory: Preferences_localMemory;
}

function Add(props: AddProps): JSX.Element {
    // Form --------------------------------
    const { register, handleSubmit, formState, reset } = useForm<WordModel>({
        defaultValues: {
            category: "No Category"
        }
    });

    // Submit
    const [loading, setLoading] = useState<boolean>(false);
    const [newCategory, setNewCategory] = useState<string>("");

    async function submit(word: WordModel) {
        try {
            if (loading) return;
            setLoading(true);

            // Checking that the voice corresponds to the language
            const preferences = props.preferences_localMemory.preferences;
            if (preferences.speechLanguage?.slice(0, 5) != preferences.speechVoice?.slice(0, 5)) {
                setIsOpen(true);
                notify.error("Please select a Voice.");
                return;
            }
            if (newCategory?.length > 20) return;

            // Saving the word
            word.language = preferences.speechLanguage;
            word.voice = preferences.speechVoice;
            word.highQuality = preferences.highQuality;
            word.myImage = myImage;

            word.wordTranslation = (document.getElementById("translationInput") as HTMLInputElement).value;
            word.sentence = document.getElementById("sentenceTextarea").innerHTML;
            word.sentenceTranslation = document.getElementById("translationTextarea").innerHTML;

            if (newCategory) word.category = newCategory;
            else if (!word.category) word.category = "No";

            const message = await props.words_localMemory.add(word);
            notify.success(message);

            // Reset fields
            reset({
                word: "",
                wordTranslation: "",
                sentence: "",
                sentenceTranslation: "",
                category: word.category
            });
            setMyImage(null);
            setImageSource(null);
            setWordExists(false);
            setNewCategory(null);

            setWordTranslation_value('');
            setSentence_value('');
            setSentenceTranslation_value('');

            setWordInput_toLink('');
            setSentenceInput_toLink('');

            props.words_localMemory.updateCount();
            if (word.category) props.words_localMemory.updateCategories(word.category);
            setLoading(false);
        }
        catch (error: any) {
            errorMessages(error);
            setLoading(false);
        }
    }

    // Displaying selected image --------------------------------
    const [imageSource, setImageSource] = useState<string>(null);
    const [myImage, setMyImage] = useState<File>(null);
    const fileTypes = ["JPG", "PNG", "GIF", "jpeg", "avif", "jfif", "pjpeg", "pjp", "apng", "webp"];

    function previewHandler(image: File): void {

        setMyImage(image); // to send to the server

        const fileReader = new FileReader();
        fileReader.onload = (event: ProgressEvent) => {
            const base64Image = (event.target as FileReader).result.toString();
            setImageSource(base64Image);
        }
        fileReader.readAsDataURL(image);
    };

    // Overlay --------------------------------
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleOverlay = () => {
        setIsOpen(!isOpen);
    };

    // Is Exists + Help Links --------------------------------
    const [wordExists, setWordExists] = useState<boolean>(false);
    const [wordInput_toLink, setWordInput_toLink] = useState<string>("");
    const [sentenceInput_toLink, setSentenceInput_toLink] = useState<string>("");

    function isExistsHandler(word: string): void {
        const exists = props.words_localMemory.isExists(word);
        exists ? setWordExists(true) : setWordExists(false);
    }

    // Set New Category --------------------------------
    function setNewCategoryHandler(args: SyntheticEvent): void {
        const category = (args.target as HTMLInputElement).value;
        setNewCategory(category);
    }

    // Setting the text in "FileUploader" --------------------------------
    let isMobile = useMediaQuery({ maxWidth: 417 });

    // Button style when loading ----------------------------------------------------------------
    const buttonLoadingStyle = {
        color: "black",
        backgroundColor: "#FFC439",
        border: "1px solid #FFC439"
    };

    // AI - translation + wordToSentence ----------------------------------------------------------------
    const [aiLoading_1, setAiLoading_1] = useState<boolean>(false);
    const [aiLoading_2, setAiLoading_2] = useState<boolean>(false);
    const [aiLoading_3, setAiLoading_3] = useState<boolean>(false);

    const [wordTranslation_value, setWordTranslation_value] = useState<string>('');
    const [sentence_value, setSentence_value] = useState<string>('');
    const [sentenceTranslation_value, setSentenceTranslation_value] = useState<string>('');

    async function AI_wordTranslation(word: string): Promise<void> {
        try {
            if (word) {
                setWordTranslation_value("");
                setAiLoading_1(true);
                const preferences = props.preferences_localMemory.preferences;
                const translation = await artificialIntelligence_webAccess.translation(preferences.sourceLanguage, preferences.targetLanguage, word);
                setWordTranslation_value(translation);
                setAiLoading_1(false);
            }
        }
        catch (err) {
            setWordTranslation_value("");
            notify.error('Error in word translation: ' + err);
            setAiLoading_1(false);
        }
    }

    async function AI_sentenceTranslation(text: string): Promise<void> {
        try {
            if (text) {
                setSentenceTranslation_value("");
                setAiLoading_2(true);
                const preferences = props.preferences_localMemory.preferences;
                const translation = await artificialIntelligence_webAccess.translation(preferences.sourceLanguage, preferences.targetLanguage, text);
                setSentenceTranslation_value(translation);
                setAiLoading_2(false);
            }
        }
        catch (err) {
            setSentenceTranslation_value("");
            notify.error('Error in sentence translation: ' + err);
            setAiLoading_2(false);
        }
    }

    async function AI_wordToSentence(word: string): Promise<void> {
        try {
            if (!aiLoading_3 && word) {
                setSentence_value("");
                setAiLoading_3(true);
                const sentence = await artificialIntelligence_webAccess.wordToSentence(word);
                setSentence_value(sentence);
                setAiLoading_3(false);
                setSentenceInput_toLink(sentence);
            }
        }
        catch (err) {
            // notify.error('Error in sentence translation AI. ' + err);
            notify.error('The sentence generation server is currently overloaded; please try again later.');
            setAiLoading_3(false);
        }
    }

    // Handle - Button Clicked ------------
    function AI_wordTranslation_buttonHandler(): void {
        const word = (document.getElementById("wordInput") as HTMLInputElement).value;
        AI_wordTranslation(word);
    }

    function AI_sentenceTranslation_buttonHandler(): void {
        const sentence = document.getElementById("sentenceTextarea").innerHTML;
        AI_sentenceTranslation(sentence);
    }

    function AI_wordToSentence_buttonHandler(): void {
        const word = (document.getElementById("wordInput") as HTMLInputElement).value;
        AI_wordToSentence(word);
    }

    // Handle - Input Change ------------
    const handleInputChange_word = (args: SyntheticEvent) => {
        const word = (args.target as HTMLInputElement).value;
        isExistsHandler(word);
        setWordInput_toLink(word);
    };

    const handleInputChange_wordTranslation = (args: SyntheticEvent) => {
        const text = (args.target as HTMLInputElement).value;
        setWordTranslation_value(text);
    };

    const handleInputChange_sentence = (args: SyntheticEvent) => {
        const sentence = (args.target as HTMLInputElement).value;
        setSentence_value(sentence);
        setSentenceInput_toLink(sentence);
    };

    const handleInputChange_sentenceTranslation = (args: SyntheticEvent) => {
        setSentenceTranslation_value((args.target as HTMLInputElement).value);
    };

    const preferences = props.preferences_localMemory.preferences;

    // Adding a new word by selecting it ----------------------------------------------------------------
    const [is_selectedText_tooltipOpen, setIs_selectedText_tooltipOpen] = useState<boolean>(false)
    const [selectedText, setSelectedText] = useState<string>('');

    const handleSelect = () => {
        const selectedText = document.getSelection().toString().trim();
        setSelectedText(selectedText);
        setIs_selectedText_tooltipOpen(true);
    };

    // Clear Input ----------------------------------------------------------------
    function clearInput(): void {
        const input = (document.getElementById('wordInput') as HTMLInputElement);
        input.value = '';
        input.focus();
    }

    // Adding an image by copy and paste ----------------------------------------------------------------
    const handlePaste = (event: any) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;

        for (const item of items) {
            if (item.type.indexOf('image') !== -1) {
                const blob = item.getAsFile();
                previewHandler(blob);
            }
        }
    };

    // העתקת מילים של משרד החינוך **************************************************************************************************************************************************************************
    async function performActionForStrings() {
        let newWord: any;
        let words = props.words_localMemory.words;

        try {
            for (const key in words_7_array) {
                if (words_7_array.hasOwnProperty(key) && Array.isArray(words_7_array[key])) {
                    const arrayName = key;
                    const stringArray = words_7_array[key];

                    for (const str of stringArray) {
                        const matchingObject = words.find(word => word.category === key && word.word === str);

                        // if (!matchingObject) {
                            newWord = {
                                word: str,
                                wordTranslation: "",
                                sentence: "",
                                sentenceTranslation: "",
                                language: "en-US",
                                voice: "en-US-JennyMultilingualNeural",
                                highQuality: 1,
                                category: arrayName,
                                myImage: null,
                            };
                            submit(newWord);

                            await delay(3000);
                        // }

                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    // **************************************************************************************************************************************************************************

    // ----------------------------------------------------------------
    return (
        <span className="Add">
            <div id="addButton"
                onClick={toggleOverlay}
                style={isOpen ? buttonLoadingStyle : null}>
                <i className="fa-solid fa-plus"></i> ADD
            </div>

            <Overlay isOpen={isOpen} onClose={toggleOverlay}>
                <LanguageSelector preferences_localMemory={props.preferences_localMemory} />
                {/* העתקת מילים של משרד החינוך ************************************************************************************************************************************************************************** */}
                {/* <button style={{ background: 'red' }} onClick={performActionForStrings}>button</button> */}
                {/* ************************************************************************************************************************************************************************** */}
                <form onSubmit={handleSubmit(submit)} id="form_add">

                    <div>

                        {/* 1 - Word */}
                        <div>
                            <label id="wordLabel" className="label_add" htmlFor="wordInput">Word </label>
                            <div>
                                <div className="errorMessage">
                                    <span> {wordExists ? "The word already exists. " : ""} </span>
                                    <span>{formState.errors.word?.message}</span>
                                </div>
                                <input
                                    id="wordInput"
                                    type="text"
                                    dir="auto"
                                    autoFocus
                                    {...register("word", {
                                        required: { value: true, message: "Missing Word. " },
                                        maxLength: { value: 100, message: "Up to 100 chars. " }
                                    })}
                                    onChange={handleInputChange_word}
                                />
                            </div>
                            <div onClick={clearInput}>
                                <i className="fa-solid fa-x"></i>
                            </div>
                        </div>

                        {/* 2 - Translation */}
                        <div>
                            <label className="translationLabel label_add" htmlFor="translationInput">Translation </label>
                            <div>
                                <div className="errorMessage">{formState.errors.wordTranslation?.message}</div>
                                <input
                                    id="translationInput"
                                    type="text"
                                    dir="auto"
                                    {...register("wordTranslation", {
                                        maxLength: { value: 100, message: "Up to 100 chars. " }
                                    })}
                                    value={wordTranslation_value}
                                    onChange={handleInputChange_wordTranslation}
                                />
                            </div>
                            <div id="wordTranslationA">
                                <span id="wordTranslation_button"
                                    onClick={AI_wordTranslation_buttonHandler}
                                    data-tooltip-id="wordTranslation_tooltip" data-tooltip-content="Translation"
                                >
                                    {aiLoading_1 ?
                                        <i className="fa-brands fa-airbnb fa-flip helpButtons"></i> :
                                        <i className="fa-brands fa-airbnb helpButtons"></i>}
                                </span>
                                <Tooltip id="wordTranslation_tooltip" />
                                <a className="translationA" href={`https://translate.google.com/?sl=${preferences.sourceLanguage}&tl=${preferences.targetLanguage}&text=${wordInput_toLink}&op=translate`} target="_blank">
                                    <i className="fa-solid fa-arrow-up-right-from-square helpButtons"
                                        data-tooltip-id="wordTranslation_A_tooltip" data-tooltip-content="Translation"
                                    ></i>
                                </a>
                                <Tooltip id="wordTranslation_A_tooltip" />
                            </div>
                        </div>

                        {/* 3 - Sentence */}
                        <div>
                            <label id="sentenceLabel" className="label_add" htmlFor="sentenceTextarea">
                                Sentence {isMobile ? "" : <br />}
                                <i
                                    className="fa-solid fa-circle-exclamation"
                                    data-tooltip-id="add-word-tooltip"
                                    data-tooltip-content="Tip: Mark a word from the sentence to add it to your vocabulary."
                                    data-tooltip-place="bottom"
                                ></i>
                                <Tooltip id="add-word-tooltip" />
                            </label>
                            <div>
                                <div className="errorMessage">{formState.errors.sentence?.message}</div>
                                <textarea
                                    id="sentenceTextarea"
                                    dir="auto"
                                    {...register("sentence", {
                                        maxLength: { value: 1000, message: "Up to 1000 chars. " }
                                    })}
                                    value={sentence_value}
                                    onChange={handleInputChange_sentence}

                                    // Adding a new word by selecting it
                                    onMouseUp={handleSelect}
                                    onTouchEnd={handleSelect}
                                    onSelect={handleSelect}
                                    data-tooltip-id="selected-text-tooltip"
                                    data-tooltip-place="bottom"
                                ></textarea>
                                <Tooltip id="selected-text-tooltip"
                                    isOpen={is_selectedText_tooltipOpen}
                                    clickable={true}
                                    opacity='1'
                                >
                                    {
                                        is_selectedText_tooltipOpen &&
                                        <TooltipSelectedText
                                            selectedText={selectedText}
                                            setIs_selectedText_tooltipOpen={setIs_selectedText_tooltipOpen}
                                        />
                                    }
                                </Tooltip>
                            </div>
                            <div id="sentenceDivA">
                                <span id="wordToSentence_button"
                                    onClick={AI_wordToSentence_buttonHandler}
                                    data-tooltip-id="wordToSentence_tooltip" data-tooltip-content="Create sentence"
                                >
                                    {aiLoading_3 ?
                                        <i className="fa-brands fa-airbnb fa-flip helpButtons"></i> :
                                        <i className="fa-brands fa-airbnb helpButtons"></i>}
                                </span>
                                <Tooltip id="wordToSentence_tooltip" />
                                <a href={`https://sentence.yourdictionary.com/${wordInput_toLink}`} target="_blank">
                                    <i className="fa-solid fa-arrow-up-right-from-square helpButtons"
                                        data-tooltip-id="wordToSentence_A1_tooltip" data-tooltip-content="Sentences"
                                    ></i>
                                </a>
                                <Tooltip id="wordToSentence_A1_tooltip" />
                                <a href={`https://www.oxfordlearnersdictionaries.com/definition/english/${wordInput_toLink}_1?q=${wordInput_toLink}`} target="_blank">
                                    <i className="fa-solid fa-arrow-up-right-from-square helpButtons"
                                        data-tooltip-id="wordToSentence_A2_tooltip" data-tooltip-content="Sentences"
                                    ></i>
                                </a>
                                <Tooltip id="wordToSentence_A2_tooltip" />
                            </div>
                        </div>

                        {/* 4 - Translation */}
                        <div>
                            <label className="translationLabel label_add" htmlFor="translationTextarea">Translation </label>
                            <div>
                                <div className="errorMessage">{formState.errors.sentenceTranslation?.message}</div>
                                <textarea
                                    id="translationTextarea"
                                    dir="auto"
                                    {...register("sentenceTranslation", {
                                        maxLength: { value: 1000, message: "Up to 1000 chars. " }
                                    })}
                                    value={sentenceTranslation_value}
                                    onChange={handleInputChange_sentenceTranslation}
                                    onClick={() => { setIs_selectedText_tooltipOpen(false) }}
                                ></textarea>
                            </div>
                            <div id="sentenceTranslationA">
                                <span id="sentenceTranslation_button"
                                    onClick={AI_sentenceTranslation_buttonHandler}
                                    data-tooltip-id="sentenceTranslation_tooltip" data-tooltip-content="Translation"
                                >
                                    {aiLoading_2 ?
                                        <i className="fa-brands fa-airbnb fa-flip helpButtons"></i> :
                                        <i className="fa-brands fa-airbnb helpButtons"></i>}
                                </span>
                                <Tooltip id="sentenceTranslation_tooltip" />
                                <a className="translationA" href={`https://translate.google.com/?sl=${preferences.sourceLanguage}&tl=${preferences.targetLanguage}&text=${sentenceInput_toLink}&op=translate`} target="_blank">
                                    <i className="fa-solid fa-arrow-up-right-from-square helpButtons"
                                        data-tooltip-id="sentenceTranslation_A_tooltip" data-tooltip-content="Translation"
                                    ></i>
                                </a>
                                <Tooltip id="sentenceTranslation_A_tooltip" />
                            </div>
                        </div>

                        {/* 5 - Picture */}
                        <div id="pictureDiv">
                            <label id="pictureLabel" className="label_add">Picture</label>
                            <div>
                                <div>
                                    <FileUploader
                                        handleChange={previewHandler}
                                        types={fileTypes}
                                        children={
                                            <div id="fileUploader">
                                                {isMobile ? <span>Drag <br />or Select</span> : "Drag an image here or click to select."}
                                            </div>
                                        }
                                    />

                                    <div
                                        id="imgDiv"
                                        onPaste={handlePaste}
                                        style={!imageSource ? {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        } : {}}
                                    >
                                        {imageSource ?
                                            <>
                                                <img src={imageSource} />
                                                <i className="fa-solid fa-xmark"
                                                    onClick={() => { setImageSource(""); setMyImage(null); }}></i>
                                            </>
                                            :
                                            <>{isMobile ? `Paste image here` : "Copy an image and paste it here."}</>
                                        }
                                    </div>
                                </div>
                                <div id="pictureDivA">
                                    <a id="pictureWordA" href={`https://www.google.co.il/search?q=${wordInput_toLink}&newwindow=1&hl=iw&authuser=0&tbm=isch&sxsrf=AB5stBhij1cjd3mZLhIHGZawLzmaMvftSA%3A1688891509574&source=hp&biw=1279&bih=1271&ei=dXCqZIn9IMjkxc8Ptfy1oAg&iflsig=AD69kcEAAAAAZKp-hYStKlzIu200UVP20itWW70ek2xe&ved=0ahUKEwjJi_aTm4GAAxVIcvEDHTV-DYQQ4dUDCAc&uact=5&oq=test&gs_lcp=CgNpbWcQAzIECCMQJzIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDoHCCMQ6gIQJzoICAAQgAQQsQNQ6QpY_BVgmBtoAXAAeACAAaIBiAGTBJIBAzAuNJgBAKABAaoBC2d3cy13aXotaW1nsAEK&sclient=img`} target="_blank">
                                        <i className="fa-solid fa-arrow-up-right-from-square helpButtons"
                                            data-tooltip-id="pictureWord_A_tooltip" data-tooltip-content="Images to word"
                                        ></i>
                                    </a>
                                    <Tooltip id="pictureWord_A_tooltip" />
                                    <a id="pictureSentenceA" href={`https://www.google.co.il/search?q=${sentenceInput_toLink}&newwindow=1&hl=iw&authuser=0&tbm=isch&sxsrf=AB5stBhij1cjd3mZLhIHGZawLzmaMvftSA%3A1688891509574&source=hp&biw=1279&bih=1271&ei=dXCqZIn9IMjkxc8Ptfy1oAg&iflsig=AD69kcEAAAAAZKp-hYStKlzIu200UVP20itWW70ek2xe&ved=0ahUKEwjJi_aTm4GAAxVIcvEDHTV-DYQQ4dUDCAc&uact=5&oq=test&gs_lcp=CgNpbWcQAzIECCMQJzIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDoHCCMQ6gIQJzoICAAQgAQQsQNQ6QpY_BVgmBtoAXAAeACAAaIBiAGTBJIBAzAuNJgBAKABAaoBC2d3cy13aXotaW1nsAEK&sclient=img`} target="_blank">
                                        <i className="fa-solid fa-arrow-up-right-from-square helpButtons"
                                            data-tooltip-id="pictureSentence_A_tooltip" data-tooltip-content="Images to sentence"
                                        ></i>
                                    </a>
                                    <Tooltip id="pictureSentence_A_tooltip" />
                                </div>
                            </div>
                        </div>

                        <div id="categoryAndNewCategoryDiv">
                            {/* 6 - Category */}
                            <div>
                                <label htmlFor="categorySelect" className="label_add">Category </label>
                                <select id="categorySelect" className="select_add" {...register("category")} >
                                    {
                                        !props.words_localMemory.categories.includes("No Category")
                                        &&
                                        <option value={"No Category"}>No Category</option>
                                    }
                                    {props.words_localMemory.categories?.map((c, index) => <option key={index} value={c}>{c}</option>)}
                                </select>
                            </div>

                            {/* 7 - New Category */}
                            <div id="newCategoryDiv">
                                <label id="newCategoryLabel" className="label_add" htmlFor="newCategoryInput">New Category </label>
                                <div>
                                    {(newCategory?.length > 20) && <div className="errorMessage">Up to 20 chars. </div>}
                                    <input id="newCategoryInput" type="text" dir="auto" onChange={setNewCategoryHandler} />
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* 10 - Buttons */}
                    <div id="buttonsDiv">
                        <span id="closeSpan" onClick={toggleOverlay}>Close</span>
                        {
                            loading ?
                                <button><i className="fa-solid fa-plus fa-flip" style={buttonLoadingStyle}></i></button>
                                :
                                <button><i className="fa-solid fa-plus"></i></button>
                        }
                    </div>

                </form>
            </Overlay>
        </span>
    );
}

export default observer(Add);
