// https://www.npmjs.com/package/notyf
import { Notyf } from "notyf";

class NotifyService {

    private notification = new Notyf({
        duration: 2000,
        position: { x: "center", y: "top" },
        dismissible: true
    });

    public success(message: string): void {
        this.notification.success(message);
    }

    public error(err: any): void {
        this.notification.error(err);
    }
}

const notify = new NotifyService();

export default notify;