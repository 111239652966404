import axios from 'axios';
import urls from './Urls';

class AI_webAccess {

    public async translation(sourceLanguage: string, targetLanguage: string, text: string): Promise<string> {
        const body = { sourceLanguage, targetLanguage, text }
        const response = await axios.post<string>(urls.AI.translation, body);
        const translation = response.data;
        return translation;
    }

    public async wordToSentence(word: string): Promise<string> {
        const response = await axios.get<string>(urls.AI.wordToSentence + word);
        const sentence = response.data;
        return sentence;
    }
}

const ai_webAccess = new AI_webAccess();

export default ai_webAccess;