export type LanguageAndVoices = {
    language: Language,
    voices: Voice[]
}

type Language = {
    name: string,
    value: string
}

type Voice = {
    name: string,
    value: string
}

export const languagesAndVoices: LanguageAndVoices[] = [
    {
        language: {
            name: "Afrikaans (South Africa)",
            value: "af-ZA"
        },
        voices: [
            {
                name: "Female",
                value: "af-ZA-AdriNeural"
            },
            {
                name: "Male",
                value: "af-ZA-WillemNeural"
            }
        ]
    },
    {
        language: {
            name: "Amharic (Ethiopia)",
            value: "am-ET"
        },
        voices: [
            {
                name: "Female",
                value: "am-ET-MekdesNeural"
            },
            {
                name: "Male",
                value: "am-ET-AmehaNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (United Arab Emirates)",
            value: "ar-AE"
        },
        voices: [
            {
                name: "Female",
                value: "ar-AE-FatimaNeural"
            },
            {
                name: "Male",
                value: "ar-AE-HamdanNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Bahrain)",
            value: "ar-BH"
        },
        voices: [
            {
                name: "Female",
                value: "ar-BH-LailaNeural"
            },
            {
                name: "Male",
                value: "ar-BH-AliNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Algeria)",
            value: "ar-DZ"
        },
        voices: [
            {
                name: "Female",
                value: "ar-DZ-AminaNeural"
            },
            {
                name: "Male",
                value: "ar-DZ-IsmaelNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Egypt)",
            value: "ar-EG"
        },
        voices: [
            {
                name: "Female",
                value: "ar-EG-SalmaNeural"
            },
            {
                name: "Male",
                value: "ar-EG-ShakirNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Iraq)",
            value: "ar-IQ"
        },
        voices: [
            {
                name: "Female",
                value: "ar-IQ-RanaNeural"
            },
            {
                name: "Male",
                value: "ar-IQ-BasselNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Jordan)",
            value: "ar-JO"
        },
        voices: [
            {
                name: "Female",
                value: "ar-JO-SanaNeural"
            },
            {
                name: "Male",
                value: "ar-JO-TaimNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Kuwait)",
            value: "ar-KW"
        },
        voices: [
            {
                name: "Female",
                value: "ar-KW-NouraNeural"
            },
            {
                name: "Male",
                value: "ar-KW-FahedNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Lebanon)",
            value: "ar-LB"
        },
        voices: [
            {
                name: "Female",
                value: "ar-LB-LaylaNeural"
            },
            {
                name: "Male",
                value: "ar-LB-RamiNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Libya)",
            value: "ar-LY"
        },
        voices: [
            {
                name: "Female",
                value: "ar-LY-ImanNeural"
            },
            {
                name: "Male",
                value: "ar-LY-OmarNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Morocco)",
            value: "ar-MA"
        },
        voices: [
            {
                name: "Female",
                value: "ar-MA-MounaNeural"
            },
            {
                name: "Male",
                value: "ar-MA-JamalNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Oman)",
            value: "ar-OM"
        },
        voices: [
            {
                name: "Female",
                value: "ar-OM-AyshaNeural"
            },
            {
                name: "Male",
                value: "ar-OM-AbdullahNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Qatar)",
            value: "ar-QA"
        },
        voices: [
            {
                name: "Female",
                value: "ar-QA-AmalNeural"
            },
            {
                name: "Male",
                value: "ar-QA-MoazNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Saudi Arabia)",
            value: "ar-SA"
        },
        voices: [
            {
                name: "Female",
                value: "ar-SA-ZariyahNeural"
            },
            {
                name: "Male",
                value: "ar-SA-HamedNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Syria)",
            value: "ar-SY"
        },
        voices: [
            {
                name: "Female",
                value: "ar-SY-AmanyNeural"
            },
            {
                name: "Male",
                value: "ar-SY-LaithNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Tunisia)",
            value: "ar-TN"
        },
        voices: [
            {
                name: "Female",
                value: "ar-TN-ReemNeural"
            },
            {
                name: "Male",
                value: "ar-TN-HediNeural"
            }
        ]
    },
    {
        language: {
            name: "Arabic (Yemen)",
            value: "ar-YE"
        },
        voices: [
            {
                name: "Female",
                value: "ar-YE-MaryamNeural"
            },
            {
                name: "Male",
                value: "ar-YE-SalehNeural"
            }
        ]
    },
    {
        language: {
            name: "Azerbaijani (Latin, Azerbaijan)",
            value: "az-AZ"
        },
        voices: [
            {
                name: "Female",
                value: "az-AZ-BanuNeural"
            },
            {
                name: "Male",
                value: "az-AZ-BabekNeural"
            }
        ]
    },
    {
        language: {
            name: "Bulgarian (Bulgaria)",
            value: "bg-BG"
        },
        voices: [
            {
                name: "Female",
                value: "bg-BG-KalinaNeural"
            },
            {
                name: "Male",
                value: "bg-BG-BorislavNeural"
            }
        ]
    },
    {
        language: {
            name: "Bangla (Bangladesh)",
            value: "bn-BD"
        },
        voices: [
            {
                name: "Female",
                value: "bn-BD-NabanitaNeural"
            },
            {
                name: "Male",
                value: "bn-BD-PradeepNeural"
            }
        ]
    },
    {
        language: {
            name: "Bengali (India)",
            value: "bn-IN"
        },
        voices: [
            {
                name: "Female",
                value: "bn-IN-TanishaaNeural"
            },
            {
                name: "Male",
                value: "bn-IN-BashkarNeural"
            }
        ]
    },
    {
        language: {
            name: "Bosnian (Bosnia and Herzegovina)",
            value: "bs-BA"
        },
        voices: [
            {
                name: "Female",
                value: "bs-BA-VesnaNeural"
            },
            {
                name: "Male",
                value: "bs-BA-GoranNeural"
            }
        ]
    },
    {
        language: {
            name: "Catalan",
            value: "ca-ES"
        },
        voices: [
            {
                name: "Female",
                value: "ca-ES-JoanaNeural"
            },
            {
                name: "Male",
                value: "ca-ES-EnricNeural"
            }
        ]
    },
    {
        language: {
            name: "Czech (Czechia)",
            value: "cs-CZ"
        },
        voices: [
            {
                name: "Female",
                value: "cs-CZ-VlastaNeural"
            },
            {
                name: "Male",
                value: "cs-CZ-AntoninNeural"
            }
        ]
    },
    {
        language: {
            name: "Welsh (United Kingdom)",
            value: "cy-GB"
        },
        voices: [
            {
                name: "Female",
                value: "cy-GB-NiaNeural"
            },
            {
                name: "Male",
                value: "cy-GB-AledNeural"
            }
        ]
    },
    {
        language: {
            name: "Danish (Denmark)",
            value: "da-DK"
        },
        voices: [
            {
                name: "Female",
                value: "da-DK-ChristelNeural"
            },
            {
                name: "Male",
                value: "da-DK-JeppeNeural"
            }
        ]
    },
    {
        language: {
            name: "German (Austria)",
            value: "de-AT"
        },
        voices: [
            {
                name: "Female",
                value: "de-AT-IngridNeural"
            },
            {
                name: "Male",
                value: "de-AT-JonasNeural"
            }
        ]
    },
    {
        language: {
            name: "German (Switzerland)",
            value: "de-CH"
        },
        voices: [
            {
                name: "Female",
                value: "de-CH-LeniNeural"
            },
            {
                name: "Male",
                value: "de-CH-JanNeural"
            }
        ]
    },
    {
        language: {
            name: "German (Germany)",
            value: "de-DE"
        },
        voices: [
            {
                name: "Female",
                value: "de-DE-KatjaNeural"
            },
            {
                name: "Male",
                value: "de-DE-ConradNeural"
            }
        ]
    },
    {
        language: {
            name: "Greek (Greece)",
            value: "el-GR"
        },
        voices: [
            {
                name: "Female",
                value: "el-GR-AthinaNeural"
            },
            {
                name: "Male",
                value: "el-GR-NestorasNeural"
            }
        ]
    },
    {
        language: {
            name: "English (Australia)",
            value: "en-AU"
        },
        voices: [
            {
                name: "Female",
                value: "en-AU-NatashaNeural"
            },
            {
                name: "Male",
                value: "en-AU-WilliamNeural"
            }
        ]
    },
    {
        language: {
            name: "English (Canada)",
            value: "en-CA"
        },
        voices: [
            {
                name: "Female",
                value: "en-CA-ClaraNeural"
            },
            {
                name: "Male",
                value: "en-CA-LiamNeural"
            }
        ]
    },
    {
        language: {
            name: "English (United Kingdom)",
            value: "en-GB"
        },
        voices: [
            {
                name: "Female",
                value: "en-GB-SoniaNeural"
            },
            {
                name: "Male",
                value: "en-GB-RyanNeural"
            }
        ]
    },
    {
        language: {
            name: "English (Hong Kong SAR)",
            value: "en-HK"
        },
        voices: [
            {
                name: "Female",
                value: "en-HK-YanNeural"
            },
            {
                name: "Male",
                value: "en-HK-SamNeural"
            }
        ]
    },
    {
        language: {
            name: "English (Ireland)",
            value: "en-IE"
        },
        voices: [
            {
                name: "Female",
                value: "en-IE-EmilyNeural"
            },
            {
                name: "Male",
                value: "en-IE-ConnorNeural"
            }
        ]
    },
    {
        language: {
            name: "English (India)",
            value: "en-IN"
        },
        voices: [
            {
                name: "Female",
                value: "en-IN-NeerjaNeural"
            },
            {
                name: "Male",
                value: "en-IN-PrabhatNeural"
            }
        ]
    },
    {
        language: {
            name: "English (Kenya)",
            value: "en-KE"
        },
        voices: [
            {
                name: "Female",
                value: "en-KE-AsiliaNeural"
            },
            {
                name: "Male",
                value: "en-KE-ChilembaNeural"
            }
        ]
    },
    {
        language: {
            name: "English (Nigeria)",
            value: "en-NG"
        },
        voices: [
            {
                name: "Female",
                value: "en-NG-EzinneNeural"
            },
            {
                name: "Male",
                value: "en-NG-AbeoNeural"
            }
        ]
    },
    {
        language: {
            name: "English (New Zealand)",
            value: "en-NZ"
        },
        voices: [
            {
                name: "Female",
                value: "en-NZ-MollyNeural"
            },
            {
                name: "Male",
                value: "en-NZ-MitchellNeural"
            }
        ]
    },
    {
        language: {
            name: "English (Philippines)",
            value: "en-PH"
        },
        voices: [
            {
                name: "Female",
                value: "en-PH-RosaNeural"
            },
            {
                name: "Male",
                value: "en-PH-JamesNeural"
            }
        ]
    },
    {
        language: {
            name: "English (Singapore)",
            value: "en-SG"
        },
        voices: [
            {
                name: "Female",
                value: "en-SG-LunaNeural"
            },
            {
                name: "Male",
                value: "en-SG-WayneNeural"
            }
        ]
    },
    {
        language: {
            name: "English (Tanzania)",
            value: "en-TZ"
        },
        voices: [
            {
                name: "Female",
                value: "en-TZ-ImaniNeural"
            },
            {
                name: "Male",
                value: "en-TZ-ElimuNeural"
            }
        ]
    },
    {
        language: {
            name: "English (United States)",
            value: "en-US"
        },
        voices: [
            {
                name: "Female",
                value: "en-US-JennyMultilingualNeural"
            },
            {
                name: "Male",
                value: "en-US-GuyNeural"
            }
        ]
    },
    {
        language: {
            name: "English (South Africa)",
            value: "en-ZA"
        },
        voices: [
            {
                name: "Female",
                value: "en-ZA-LeahNeural"
            },
            {
                name: "Male",
                value: "en-ZA-LukeNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Argentina)",
            value: "es-AR"
        },
        voices: [
            {
                name: "Female",
                value: "es-AR-ElenaNeural"
            },
            {
                name: "Male",
                value: "es-AR-TomasNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Bolivia)",
            value: "es-BO"
        },
        voices: [
            {
                name: "Female",
                value: "es-BO-SofiaNeural"
            },
            {
                name: "Male",
                value: "es-BO-MarceloNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Chile)",
            value: "es-CL"
        },
        voices: [
            {
                name: "Female",
                value: "es-CL-CatalinaNeural"
            },
            {
                name: "Male",
                value: "es-CL-LorenzoNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Colombia)",
            value: "es-CO"
        },
        voices: [
            {
                name: "Female",
                value: "es-CO-SalomeNeural"
            },
            {
                name: "Male",
                value: "es-CO-GonzaloNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Costa Rica)",
            value: "es-CR"
        },
        voices: [
            {
                name: "Female",
                value: "es-CR-MariaNeural"
            },
            {
                name: "Male",
                value: "es-CR-JuanNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Cuba)",
            value: "es-CU"
        },
        voices: [
            {
                name: "Female",
                value: "es-CU-BelkysNeural"
            },
            {
                name: "Male",
                value: "es-CU-ManuelNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Dominican Republic)",
            value: "es-DO"
        },
        voices: [
            {
                name: "Female",
                value: "es-DO-RamonaNeural"
            },
            {
                name: "Male",
                value: "es-DO-EmilioNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Ecuador)",
            value: "es-EC"
        },
        voices: [
            {
                name: "Female",
                value: "es-EC-AndreaNeural"
            },
            {
                name: "Male",
                value: "es-EC-LuisNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Spain)",
            value: "es-ES"
        },
        voices: [
            {
                name: "Female",
                value: "es-ES-ElviraNeural"
            },
            {
                name: "Male",
                value: "es-ES-AlvaroNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Equatorial Guinea)",
            value: "es-GQ"
        },
        voices: [
            {
                name: "Female",
                value: "es-GQ-TeresaNeural"
            },
            {
                name: "Male",
                value: "es-GQ-JavierNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Guatemala)",
            value: "es-GT"
        },
        voices: [
            {
                name: "Female",
                value: "es-GT-MartaNeural"
            },
            {
                name: "Male",
                value: "es-GT-AndresNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Honduras)",
            value: "es-HN"
        },
        voices: [
            {
                name: "Female",
                value: "es-HN-KarlaNeural"
            },
            {
                name: "Male",
                value: "es-HN-CarlosNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Mexico)",
            value: "es-MX"
        },
        voices: [
            {
                name: "Female",
                value: "es-MX-DaliaNeural"
            },
            {
                name: "Male",
                value: "es-MX-JorgeNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Nicaragua)",
            value: "es-NI"
        },
        voices: [
            {
                name: "Female",
                value: "es-NI-YolandaNeural"
            },
            {
                name: "Male",
                value: "es-NI-FedericoNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Panama)",
            value: "es-PA"
        },
        voices: [
            {
                name: "Female",
                value: "es-PA-MargaritaNeural"
            },
            {
                name: "Male",
                value: "es-PA-RobertoNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Peru)",
            value: "es-PE"
        },
        voices: [
            {
                name: "Female",
                value: "es-PE-CamilaNeural"
            },
            {
                name: "Male",
                value: "es-PE-AlexNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Puerto Rico)",
            value: "es-PR"
        },
        voices: [
            {
                name: "Female",
                value: "es-PR-KarinaNeural"
            },
            {
                name: "Male",
                value: "es-PR-VictorNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Paraguay)",
            value: "es-PY"
        },
        voices: [
            {
                name: "Female",
                value: "es-PY-TaniaNeural"
            },
            {
                name: "Male",
                value: "es-PY-MarioNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (El Salvador)",
            value: "es-SV"
        },
        voices: [
            {
                name: "Female",
                value: "es-SV-LorenaNeural"
            },
            {
                name: "Male",
                value: "es-SV-RodrigoNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (United States)",
            value: "es-US"
        },
        voices: [
            {
                name: "Female",
                value: "es-US-PalomaNeural"
            },
            {
                name: "Male",
                value: "es-US-AlonsoNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Uruguay)",
            value: "es-UY"
        },
        voices: [
            {
                name: "Female",
                value: "es-UY-ValentinaNeural"
            },
            {
                name: "Male",
                value: "es-UY-MateoNeural"
            }
        ]
    },
    {
        language: {
            name: "Spanish (Venezuela)",
            value: "es-VE"
        },
        voices: [
            {
                name: "Female",
                value: "es-VE-PaolaNeural"
            },
            {
                name: "Male",
                value: "es-VE-SebastianNeural"
            }
        ]
    },
    {
        language: {
            name: "Estonian (Estonia)",
            value: "et-EE"
        },
        voices: [
            {
                name: "Female",
                value: "et-EE-AnuNeural"
            },
            {
                name: "Male",
                value: "et-EE-KertNeural"
            }
        ]
    },
    {
        language: {
            name: "Basque",
            value: "eu-ES"
        },
        voices: [
            {
                name: "Female",
                value: "eu-ES-AinhoaNeural"
            },
            {
                name: "Male",
                value: "eu-ES-AnderNeural"
            }
        ]
    },
    {
        language: {
            name: "Persian (Iran)",
            value: "fa-IR"
        },
        voices: [
            {
                name: "Female",
                value: "fa-IR-DilaraNeural"
            },
            {
                name: "Male",
                value: "fa-IR-FaridNeural"
            }
        ]
    },
    {
        language: {
            name: "Finnish (Finland)",
            value: "fi-FI"
        },
        voices: [
            {
                name: "Female",
                value: "fi-FI-SelmaNeural"
            },
            {
                name: "Male",
                value: "fi-FI-HarriNeural"
            }
        ]
    },
    {
        language: {
            name: "Filipino (Philippines)",
            value: "fil-PH"
        },
        voices: [
            {
                name: "Female",
                value: "fil-PH-BlessicaNeural"
            },
            {
                name: "Male",
                value: "fil-PH-AngeloNeural"
            }
        ]
    },
    {
        language: {
            name: "French (Belgium)",
            value: "fr-BE"
        },
        voices: [
            {
                name: "Female",
                value: "fr-BE-CharlineNeural"
            },
            {
                name: "Male",
                value: "fr-BE-GerardNeural"
            }
        ]
    },
    {
        language: {
            name: "French (Canada)",
            value: "fr-CA"
        },
        voices: [
            {
                name: "Female",
                value: "fr-CA-SylvieNeural"
            },
            {
                name: "Male",
                value: "fr-CA-JeanNeural"
            }
        ]
    },
    {
        language: {
            name: "French (Switzerland)",
            value: "fr-CH"
        },
        voices: [
            {
                name: "Female",
                value: "fr-CH-ArianeNeural"
            },
            {
                name: "Male",
                value: "fr-CH-FabriceNeural"
            }
        ]
    },
    {
        language: {
            name: "French (France)",
            value: "fr-FR"
        },
        voices: [
            {
                name: "Female",
                value: "fr-FR-DeniseNeural"
            },
            {
                name: "Male",
                value: "fr-FR-HenriNeural"
            }
        ]
    },
    {
        language: {
            name: "Irish (Ireland)",
            value: "ga-IE"
        },
        voices: [
            {
                name: "Female",
                value: "ga-IE-OrlaNeural"
            },
            {
                name: "Male",
                value: "ga-IE-ColmNeural"
            }
        ]
    },
    {
        language: {
            name: "Galician",
            value: "gl-ES"
        },
        voices: [
            {
                name: "Female",
                value: "gl-ES-SabelaNeural"
            },
            {
                name: "Male",
                value: "gl-ES-RoiNeural"
            }
        ]
    },
    {
        language: {
            name: "Gujarati (India)",
            value: "gu-IN"
        },
        voices: [
            {
                name: "Female",
                value: "gu-IN-DhwaniNeural"
            },
            {
                name: "Male",
                value: "gu-IN-NiranjanNeural"
            }
        ]
    },
    {
        language: {
            name: "Hebrew (Israel)",
            value: "he-IL"
        },
        voices: [
            {
                name: "Female",
                value: "he-IL-HilaNeural"
            },
            {
                name: "Male",
                value: "he-IL-AvriNeural"
            }
        ]
    },
    {
        language: {
            name: "Hindi (India)",
            value: "hi-IN"
        },
        voices: [
            {
                name: "Female",
                value: "hi-IN-SwaraNeural"
            },
            {
                name: "Male",
                value: "hi-IN-MadhurNeural"
            }
        ]
    },
    {
        language: {
            name: "Croatian (Croatia)",
            value: "hr-HR"
        },
        voices: [
            {
                name: "Female",
                value: "hr-HR-GabrijelaNeural"
            },
            {
                name: "Male",
                value: "hr-HR-SreckoNeural"
            }
        ]
    },
    {
        language: {
            name: "Hungarian (Hungary)",
            value: "hu-HU"
        },
        voices: [
            {
                name: "Female",
                value: "hu-HU-NoemiNeural"
            },
            {
                name: "Male",
                value: "hu-HU-TamasNeural"
            }
        ]
    },
    {
        language: {
            name: "Armenian (Armenia)",
            value: "hy-AM"
        },
        voices: [
            {
                name: "Female",
                value: "hy-AM-AnahitNeural"
            },
            {
                name: "Male",
                value: "hy-AM-HaykNeural"
            }
        ]
    },
    {
        language: {
            name: "Indonesian (Indonesia)",
            value: "id-ID"
        },
        voices: [
            {
                name: "Female",
                value: "id-ID-GadisNeural"
            },
            {
                name: "Male",
                value: "id-ID-ArdiNeural"
            }
        ]
    },
    {
        language: {
            name: "Icelandic (Iceland)",
            value: "is-IS"
        },
        voices: [
            {
                name: "Female",
                value: "is-IS-GudrunNeural"
            },
            {
                name: "Male",
                value: "is-IS-GunnarNeural"
            }
        ]
    },
    {
        language: {
            name: "Italian (Italy)",
            value: "it-IT"
        },
        voices: [
            {
                name: "Female",
                value: "it-IT-ElsaNeural"
            },
            {
                name: "Male",
                value: "it-IT-IsabellaNeural"
            }
        ]
    },
    {
        language: {
            name: "Japanese (Japan)",
            value: "ja-JP"
        },
        voices: [
            {
                name: "Female",
                value: "ja-JP-NanamiNeural"
            },
            {
                name: "Male",
                value: "ja-JP-KeitaNeural"
            }
        ]
    },
    {
        language: {
            name: "Javanese (Latin, Indonesia)",
            value: "jv-ID"
        },
        voices: [
            {
                name: "Female",
                value: "jv-ID-SitiNeural"
            },
            {
                name: "Male",
                value: "jv-ID-DimasNeural"
            }
        ]
    },
    {
        language: {
            name: "Georgian (Georgia)",
            value: "ka-GE"
        },
        voices: [
            {
                name: "Female",
                value: "ka-GE-EkaNeural"
            },
            {
                name: "Male",
                value: "ka-GE-GiorgiNeural"
            }
        ]
    },
    {
        language: {
            name: "Kazakh (Kazakhstan)",
            value: "kk-KZ"
        },
        voices: [
            {
                name: "Female",
                value: "kk-KZ-AigulNeural"
            },
            {
                name: "Male",
                value: "kk-KZ-DauletNeural"
            }
        ]
    },
    {
        language: {
            name: "Khmer (Cambodia)",
            value: "km-KH"
        },
        voices: [
            {
                name: "Female",
                value: "km-KH-SreymomNeural"
            },
            {
                name: "Male",
                value: "km-KH-PisethNeural"
            }
        ]
    },
    {
        language: {
            name: "Kannada (India)",
            value: "kn-IN"
        },
        voices: [
            {
                name: "Female",
                value: "kn-IN-SapnaNeural"
            },
            {
                name: "Male",
                value: "kn-IN-GaganNeural"
            }
        ]
    },
    {
        language: {
            name: "Korean (Korea)",
            value: "ko-KR"
        },
        voices: [
            {
                name: "Female",
                value: "ko-KR-SunHiNeural"
            },
            {
                name: "Male",
                value: "ko-KR-InJoonNeural"
            }
        ]
    },
    {
        language: {
            name: "Lao (Laos)",
            value: "lo-LA"
        },
        voices: [
            {
                name: "Female",
                value: "lo-LA-KeomanyNeural"
            },
            {
                name: "Male",
                value: "lo-LA-ChanthavongNeural"
            }
        ]
    },
    {
        language: {
            name: "Lithuanian (Lithuania)",
            value: "lt-LT"
        },
        voices: [
            {
                name: "Female",
                value: "lt-LT-OnaNeural"
            },
            {
                name: "Male",
                value: "lt-LT-LeonasNeural"
            }
        ]
    },
    {
        language: {
            name: "Latvian (Latvia)",
            value: "lv-LV"
        },
        voices: [
            {
                name: "Female",
                value: "lv-LV-EveritaNeural"
            },
            {
                name: "Male",
                value: "lv-LV-NilsNeural"
            }
        ]
    },
    {
        language: {
            name: "Macedonian (North Macedonia)",
            value: "mk-MK"
        },
        voices: [
            {
                name: "Female",
                value: "mk-MK-MarijaNeural"
            },
            {
                name: "Male",
                value: "mk-MK-AleksandarNeural"
            }
        ]
    },
    {
        language: {
            name: "Malayalam (India)",
            value: "ml-IN"
        },
        voices: [
            {
                name: "Female",
                value: "ml-IN-SobhanaNeural"
            },
            {
                name: "Male",
                value: "ml-IN-MidhunNeural"
            }
        ]
    },
    {
        language: {
            name: "Mongolian (Mongolia)",
            value: "mn-MN"
        },
        voices: [
            {
                name: "Female",
                value: "mn-MN-YesuiNeural"
            },
            {
                name: "Male",
                value: "mn-MN-BataaNeural"
            }
        ]
    },
    {
        language: {
            name: "Marathi (India)",
            value: "mr-IN"
        },
        voices: [
            {
                name: "Female",
                value: "mr-IN-AarohiNeural"
            },
            {
                name: "Male",
                value: "mr-IN-ManoharNeural"
            }
        ]
    },
    {
        language: {
            name: "Malay (Malaysia)",
            value: "ms-MY"
        },
        voices: [
            {
                name: "Female",
                value: "ms-MY-YasminNeural"
            },
            {
                name: "Male",
                value: "ms-MY-OsmanNeural"
            }
        ]
    },
    {
        language: {
            name: "Maltese (Malta)",
            value: "mt-MT"
        },
        voices: [
            {
                name: "Female",
                value: "mt-MT-GraceNeural"
            },
            {
                name: "Male",
                value: "mt-MT-JosephNeural"
            }
        ]
    },
    {
        language: {
            name: "Burmese (Myanmar)",
            value: "my-MM"
        },
        voices: [
            {
                name: "Female",
                value: "my-MM-NilarNeural"
            },
            {
                name: "Male",
                value: "my-MM-ThihaNeural"
            }
        ]
    },
    {
        language: {
            name: "Norwegian Bokmål (Norway)",
            value: "nb-NO"
        },
        voices: [
            {
                name: "Female",
                value: "nb-NO-PernilleNeural"
            },
            {
                name: "Male",
                value: "nb-NO-FinnNeural"
            }
        ]
    },
    {
        language: {
            name: "Nepali (Nepal)",
            value: "ne-NP"
        },
        voices: [
            {
                name: "Female",
                value: "ne-NP-HemkalaNeural"
            },
            {
                name: "Male",
                value: "ne-NP-SagarNeural"
            }
        ]
    },
    {
        language: {
            name: "Dutch (Belgium)",
            value: "nl-BE"
        },
        voices: [
            {
                name: "Female",
                value: "nl-BE-DenaNeural"
            },
            {
                name: "Male",
                value: "nl-BE-ArnaudNeural"
            }
        ]
    },
    {
        language: {
            name: "Dutch (Netherlands)",
            value: "nl-NL"
        },
        voices: [
            {
                name: "Female",
                value: "nl-NL-FennaNeural"
            },
            {
                name: "Male",
                value: "nl-NL-MaartenNeural"
            }
        ]
    },
    {
        language: {
            name: "Polish (Poland)",
            value: "pl-PL"
        },
        voices: [
            {
                name: "Female",
                value: "pl-PL-AgnieszkaNeural"
            },
            {
                name: "Male",
                value: "pl-PL-MarekNeural"
            }
        ]
    },
    {
        language: {
            name: "Pashto (Afghanistan)",
            value: "ps-AF"
        },
        voices: [
            {
                name: "Female",
                value: "ps-AF-LatifaNeural"
            },
            {
                name: "Male",
                value: "ps-AF-GulNawazNeural"
            }
        ]
    },
    {
        language: {
            name: "Portuguese (Brazil)",
            value: "pt-BR"
        },
        voices: [
            {
                name: "Female",
                value: "pt-BR-FranciscaNeural"
            },
            {
                name: "Male",
                value: "pt-BR-AntonioNeural"
            }
        ]
    },
    {
        language: {
            name: "Portuguese (Portugal)",
            value: "pt-PT"
        },
        voices: [
            {
                name: "Female",
                value: "pt-PT-RaquelNeural"
            },
            {
                name: "Male",
                value: "pt-PT-DuarteNeural"
            }
        ]
    },
    {
        language: {
            name: "Romanian (Romania)",
            value: "ro-RO"
        },
        voices: [
            {
                name: "Female",
                value: "ro-RO-AlinaNeural"
            },
            {
                name: "Male",
                value: "ro-RO-EmilNeural"
            }
        ]
    },
    {
        language: {
            name: "Russian (Russia)",
            value: "ru-RU"
        },
        voices: [
            {
                name: "Female",
                value: "ru-RU-SvetlanaNeural"
            },
            {
                name: "Male",
                value: "ru-RU-DmitryNeural"
            }
        ]
    },
    {
        language: {
            name: "Sinhala (Sri Lanka)",
            value: "si-LK"
        },
        voices: [
            {
                name: "Female",
                value: "si-LK-ThiliniNeural"
            },
            {
                name: "Male",
                value: "si-LK-SameeraNeural"
            }
        ]
    },
    {
        language: {
            name: "Slovak (Slovakia)",
            value: "sk-SK"
        },
        voices: [
            {
                name: "Female",
                value: "sk-SK-ViktoriaNeural"
            },
            {
                name: "Male",
                value: "sk-SK-LukasNeural"
            }
        ]
    },
    {
        language: {
            name: "Slovenian (Slovenia)",
            value: "sl-SI"
        },
        voices: [
            {
                name: "Female",
                value: "sl-SI-PetraNeural"
            },
            {
                name: "Male",
                value: "sl-SI-RokNeural"
            }
        ]
    },
    {
        language: {
            name: "Somali (Somalia)",
            value: "so-SO"
        },
        voices: [
            {
                name: "Female",
                value: "so-SO-UbaxNeural"
            },
            {
                name: "Male",
                value: "so-SO-MuuseNeural"
            }
        ]
    },
    {
        language: {
            name: "Albanian (Albania)",
            value: "sq-AL"
        },
        voices: [
            {
                name: "Female",
                value: "sq-AL-AnilaNeural"
            },
            {
                name: "Male",
                value: "sq-AL-IlirNeural"
            }
        ]
    },
    {
        language: {
            name: "Serbian (Latin, Serbia)",
            value: "sr-LATN-RS"
        },
        voices: [
            {
                name: "Female",
                value: "sr-Latn-RS-NicholasNeural"
            },
            {
                name: "Male",
                value: "sr-Latn-RS-SophieNeural"
            }
        ]
    },
    {
        language: {
            name: "Serbian (Cyrillic, Serbia)",
            value: "sr-RS"
        },
        voices: [
            {
                name: "Female",
                value: "sr-RS-SophieNeural"
            },
            {
                name: "Male",
                value: "sr-RS-NicholasNeural"
            }
        ]
    },
    {
        language: {
            name: "Sundanese (Indonesia)",
            value: "su-ID"
        },
        voices: [
            {
                name: "Female",
                value: "su-ID-TutiNeural"
            },
            {
                name: "Male",
                value: "su-ID-JajangNeural"
            }
        ]
    },
    {
        language: {
            name: "Swedish (Sweden)",
            value: "sv-SE"
        },
        voices: [
            {
                name: "Female",
                value: "sv-SE-SofieNeural"
            },
            {
                name: "Male",
                value: "sv-SE-MattiasNeural"
            }
        ]
    },
    {
        language: {
            name: "Swahili (Kenya)",
            value: "sw-KE"
        },
        voices: [
            {
                name: "Female",
                value: "sw-KE-ZuriNeural"
            },
            {
                name: "Male",
                value: "sw-KE-RafikiNeural"
            }
        ]
    },
    {
        language: {
            name: "Swahili (Tanzania)",
            value: "sw-TZ"
        },
        voices: [
            {
                name: "Female",
                value: "sw-TZ-RehemaNeural"
            },
            {
                name: "Male",
                value: "sw-TZ-DaudiNeural"
            }
        ]
    },
    {
        language: {
            name: "Tamil (India)",
            value: "ta-IN"
        },
        voices: [
            {
                name: "Female",
                value: "ta-IN-PallaviNeural"
            },
            {
                name: "Male",
                value: "ta-IN-ValluvarNeural"
            }
        ]
    },
    {
        language: {
            name: "Tamil (Sri Lanka)",
            value: "ta-LK"
        },
        voices: [
            {
                name: "Female",
                value: "ta-LK-SaranyaNeural"
            },
            {
                name: "Male",
                value: "ta-LK-KumarNeural"
            }
        ]
    },
    {
        language: {
            name: "Tamil (Malaysia)",
            value: "ta-MY"
        },
        voices: [
            {
                name: "Female",
                value: "ta-MY-KaniNeural"
            },
            {
                name: "Male",
                value: "ta-MY-SuryaNeural"
            }
        ]
    },
    {
        language: {
            name: "Tamil (Singapore)",
            value: "ta-SG"
        },
        voices: [
            {
                name: "Female",
                value: "ta-SG-VenbaNeural"
            },
            {
                name: "Male",
                value: "ta-SG-AnbuNeural"
            }
        ]
    },
    {
        language: {
            name: "Telugu (India)",
            value: "te-IN"
        },
        voices: [
            {
                name: "Female",
                value: "te-IN-ShrutiNeural"
            },
            {
                name: "Male",
                value: "te-IN-MohanNeural"
            }
        ]
    },
    {
        language: {
            name: "Thai (Thailand)",
            value: "th-TH"
        },
        voices: [
            {
                name: "Female",
                value: "th-TH-PremwadeeNeural"
            },
            {
                name: "Male",
                value: "th-TH-NiwatNeural"
            }
        ]
    },
    {
        language: {
            name: "Turkish (Turkey)",
            value: "tr-TR"
        },
        voices: [
            {
                name: "Female",
                value: "tr-TR-EmelNeural"
            },
            {
                name: "Male",
                value: "tr-TR-AhmetNeural"
            }
        ]
    },
    {
        language: {
            name: "Ukrainian (Ukraine)",
            value: "uk-UA"
        },
        voices: [
            {
                name: "Female",
                value: "uk-UA-PolinaNeural"
            },
            {
                name: "Male",
                value: "uk-UA-OstapNeural"
            }
        ]
    },
    {
        language: {
            name: "Urdu (India)",
            value: "ur-IN"
        },
        voices: [
            {
                name: "Female",
                value: "ur-IN-GulNeural"
            },
            {
                name: "Male",
                value: "ur-IN-SalmanNeural"
            }
        ]
    },
    {
        language: {
            name: "Urdu (Pakistan)",
            value: "ur-PK"
        },
        voices: [
            {
                name: "Female",
                value: "ur-PK-UzmaNeural"
            },
            {
                name: "Male",
                value: "ur-PK-AsadNeural"
            }
        ]
    },
    {
        language: {
            name: "Uzbek (Latin, Uzbekistan)",
            value: "uz-UZ"
        },
        voices: [
            {
                name: "Female",
                value: "uz-UZ-MadinaNeural"
            },
            {
                name: "Male",
                value: "uz-UZ-SardorNeural"
            }
        ]
    },
    {
        language: {
            name: "Vietnamese (Vietnam)",
            value: "vi-VN"
        },
        voices: [
            {
                name: "Female",
                value: "vi-VN-HoaiMyNeural"
            },
            {
                name: "Male",
                value: "vi-VN-NamMinhNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Wu, Simplified)",
            value: "wuu-CN"
        },
        voices: [
            {
                name: "Female",
                value: "wuu-CN-XiaotongNeural"
            },
            {
                name: "Male",
                value: "wuu-CN-YunzheNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Cantonese, Simplified)",
            value: "yue-CN"
        },
        voices: [
            {
                name: "Female",
                value: "yue-CN-XiaoMinNeural"
            },
            {
                name: "Male",
                value: "yue-CN-YunSongNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Mandarin, Simplified)",
            value: "zh-CN"
        },
        voices: [
            {
                name: "Female",
                value: "zh-CN-XiaoxiaoNeural"
            },
            {
                name: "Male",
                value: "zh-CN-YunxiNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Guangxi Accent Mandarin, Simplified)",
            value: "zh-CN-GUANGXI"
        },
        voices: [
            {
                name: "Male",
                value: "zh-CN-guangxi-YunqiNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Zhongyuan Mandarin Henan, Simplified)",
            value: "zh-CN-henan"
        },
        voices: [
            {
                name: "Male",
                value: "zh-CN-henan-YundengNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Northeastern Mandarin, Simplified)",
            value: "zh-CN-liaoning"
        },
        voices: [
            {
                name: "Female",
                value: "zh-CN-liaoning-XiaobeiNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)",
            value: "zh-CN-shaanxi"
        },
        voices: [
            {
                name: "Female",
                value: "zh-CN-shaanxi-XiaoniNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Jilu Mandarin, Simplified)",
            value: "zh-CN-shandong"
        },
        voices: [
            {
                name: "Male",
                value: "zh-CN-shandong-YunxiangNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Southwestern Mandarin, Simplified)",
            value: "zh-CN-sichuan"
        },
        voices: [
            {
                name: "Male",
                value: "zh-CN-sichuan-YunxiNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Cantonese, Traditional)",
            value: "zh-HK"
        },
        voices: [
            {
                name: "Female",
                value: "zh-HK-HiuMaanNeural"
            },
            {
                name: "Male",
                value: "zh-HK-WanLungNeural"
            }
        ]
    },
    {
        language: {
            name: "Chinese (Taiwanese Mandarin, Traditional)",
            value: "zh-TW"
        },
        voices: [
            {
                name: "Female",
                value: "zh-TW-HsiaoChenNeural"
            },
            {
                name: "Male",
                value: "zh-TW-YunJheNeural"
            }
        ]
    },
    {
        language: {
            name: "Zulu (South Africa)",
            value: "zu-ZA"
        },
        voices: [
            {
                name: "Female",
                value: "zu-ZA-ThandoNeural"
            },
            {
                name: "Male",
                value: "zu-ZA-ThembaNeural"
            }
        ]
    }
]