import { action, makeObservable, observable, runInAction } from "mobx";
import PreferencesModel from "../1_Models/PreferencesModel";
import preferences_WebAccess from "../2_WebAccess/Preferences_webAccess"

export class Preferences_localMemory {

    @observable
    public preferences: PreferencesModel = null;

    public constructor() {
        makeObservable(this);
    }

    @action
    public async save(): Promise<void> {
        if (!this.preferences) {
            const preferences = await preferences_WebAccess.get();
            runInAction(() => {
                this.preferences = preferences;
            });
        }
    }

    @action
    public async update(preferences: PreferencesModel): Promise<string> {
        const updatePreferences = await preferences_WebAccess.update(preferences);
        runInAction(() => {
            this.preferences = updatePreferences;
        });
        return "The preferences have been successfully updated!";
    }

    @action
    public clearMemory(): void {
        this.preferences = null;
    }
}

const preferences_localMemory = new Preferences_localMemory();

export default preferences_localMemory;