import "./Test.css";
import { observer } from "mobx-react";
import { Words_localMemory } from "../../../../3_LocalMemory/Words_localMemory";
import { Preferences_localMemory } from "../../../../3_LocalMemory/Preferences_localMemory";
import { useSwipeable } from "react-swipeable";
import { RefObject, useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../../5_Helpers/UseWindowDimensions";
import imageAndAudio_localMemory from "../../../../3_LocalMemory/imageAndAudio_localMemory";
import loadingImg from "../../../../4_Assets/Images/loading.gif";
import loadingWhiteImg from "../../../../4_Assets/Images/loading_white.gif";
import errorMessages from "../../../../5_Helpers/ErrorMessages";
import Update from "../../3_List/Update/Update";
import { Tooltip } from "react-tooltip";
import TooltipKeyboardShortcuts from "../../../SharedArea/TooltipKeyboardShortcuts/TooltipKeyboardShortcuts";
import TooltipSelectedText from "../../../SharedArea/TooltipSelectedText/TooltipSelectedText";
import WordModel from "../../../../1_Models/WordModel";

interface TestProps {
    words_localMemory: Words_localMemory;
    preferences_localMemory: Preferences_localMemory;
    userUuid: string;
    heightOfContent: number;
}

function Test(props: TestProps): JSX.Element {
    const userUuid = props.userUuid;
    const audioRef: RefObject<HTMLAudioElement> = useRef();
    const [words, setWords] = useState<WordModel[]>([]);
    const [sumOfWords, setSumOfWords] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState<number>();
    const [currentAudioName, setCurrentAudioName] = useState<string>();
    const score = words[currentIndex]?.score;

    const [word, set_word] = useState<string>();
    const [wordTranslation, set_wordTranslation] = useState<string>();
    const [sentence, set_sentence] = useState<string>();
    const [sentenceTranslation, set_sentenceTranslation] = useState<string>();

    useEffect(() => {
        setWords(props.words_localMemory.words.filter(w => w.display === true));
    }, []);

    useEffect(() => {
        setWords(props.words_localMemory.words.filter(w => w.display === true));
    }, [props.words_localMemory.displaySettingsModel]);

    useEffect(() => {
        setWord(0);
        setSumOfWords(props.words_localMemory.count);
    }, [words]);

    function setWord(index: number): void {
        setCurrentIndex(index);
        getImage(words[index]?.picture);
        getAudioWord(words[index]?.speechWord);
        setCurrentAudioName(words[index]?.speechWord);
        setIs_selectedText_tooltipOpen(false);

        set_word(words[index]?.word);
        set_wordTranslation(words[index]?.wordTranslation);
        set_sentence(words[index]?.sentence);
        set_sentenceTranslation(words[index]?.sentenceTranslation);

        // Saving the audio of the next word. 
        const nextIndex = index === words.length - 1 ? 0 : index + 1;
        imageAndAudio_localMemory.saveImageAndAudio(userUuid, words[nextIndex]);
    }

    // Audio --------------------------------------------------------------------------------
    function play(): void {
        // https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
        if (sumOfWords > 0) {
            setTimeout(() => { audioRef.current?.play(); }, 1000);
        }
    }

    function load() {
        audioRef.current?.load();
    }

    function next(): void {
        if (sumOfWords === 0) return;
        setHideAnswer(true);
        setDisplayScore(false);
        currentIndex < words.length - 1 ? setWord(currentIndex + 1) : setWord(0);
    }

    function previous(): void {
        if (sumOfWords === 0) return;
        setHideAnswer(true);
        setDisplayScore(false);
        currentIndex != 0 ? setWord(currentIndex - 1) : setWord(words.length - 1);
    }

    function end(): void {
        if (words[currentIndex]?.speechSentence &&
            currentAudioName !== words[currentIndex].speechSentence &&
            isSpeechSentence) {
            getAudioSentence(words[currentIndex].speechSentence);
            setCurrentAudioName(words[currentIndex].speechSentence);
            if (!muted) play();
        }
    }

    function setPlaybackRate() {
        audioRef.current.playbackRate = props.preferences_localMemory.preferences?.rateSpeech;
    }

    // -----------------------------
    const [isSpeechSentence, setIsSpeechSentence] = useState<boolean>(true);

    function speechWord() {
        load();
        getAudioWord(words[currentIndex]?.speechWord);
        setIsSpeechSentence(false);
        play();
    }

    function speechSentence() {
        load();
        if (words[currentIndex].speechSentence) {
            setIsSpeechSentence(true);
            getAudioSentence(words[currentIndex]?.speechSentence);
            setCurrentAudioName(words[currentIndex]?.speechSentence);
            play();
        }
    }

    // Hide Answer ----------------------
    const [hideAnswer, setHideAnswer] = useState<boolean>(true);
    const hideAnswerClass = hideAnswer ? 'hideAnswer' : '';

    function toggleHideAnswer(): void {
        getAudioWord(words[currentIndex]?.speechWord);
        setCurrentAudioName(words[currentIndex]?.speechWord);
        setHideAnswer(!hideAnswer);
        setIsSpeechSentence(true);
        setIs_selectedText_tooltipOpen(false);
    }

    useEffect(() => {
        load();
        if (!muted && !hideAnswer) play();
    }, [hideAnswer]);

    // Fixed a bug when you find out the translation and immediately move to the next word.
    useEffect(() => {
        setTimeout(load, 1000);
    }, [currentIndex]);

    // Muted  --------------------------------------------------------------------------------
    const [muted, setMuted] = useState<boolean>(false);
    function toggleMuted(): void {
        if (!muted) load();
        setMuted(!muted);
    }

    // Updating Score -------------------------------------------------------------------------
    const [loadingScore, setLoadingScore] = useState<boolean>(false);
    const loadingScoreClass = loadingScore ? 'flicker-text' : '';

    // Display Score
    const [displayScore, setDisplayScore] = useState<boolean>(false);
    const [scoreTimeout, setScoreTimeout] = useState<NodeJS.Timeout>();

    async function plusScore(uuid: string): Promise<void> {
        clearTimeout(scoreTimeout);
        setDisplayScore(true);

        if (score < 3) {
            setLoadingScore(true);
            await props.words_localMemory.updateScore(uuid, score + 1);
            setLoadingScore(false);
        }

        const timeout = setTimeout(() => { setDisplayScore(false); }, 500);
        setScoreTimeout(timeout);
    }

    async function minusScore(uuid: string): Promise<void> {
        clearTimeout(scoreTimeout);
        setDisplayScore(true);

        if (score > 1) {
            setLoadingScore(true);
            await props.words_localMemory.updateScore(uuid, score - 1);
            setLoadingScore(false);
        }

        const timeout = setTimeout(() => { setDisplayScore(false); }, 500);
        setScoreTimeout(timeout);
    }

    // Swipe -----------------------------------------------------------------------------------------
    const handlers = useSwipeable({
        onSwipedLeft: () => next(),
        onSwipedRight: () => previous(),
        onSwipedUp: () => plusScore(words[currentIndex].uuid),
        onSwipedDown: () => minusScore(words[currentIndex].uuid),
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    // Width ------------------------------------------------------------------------------------------
    const { width: widthOfWindow } = useWindowDimensions();

    // Listening to the keyboard keys ------------------------------------------------------------------
    const handleKeyDown = (event: KeyboardEvent) => {
        if (!isUpdateOpen) {
            const key = event.key;
            if (key === "ArrowUp") { plusScore(words[currentIndex].uuid); }
            if (key === "ArrowDown") { minusScore(words[currentIndex].uuid); }
            if (key === "ArrowRight") { next(); }
            if (key === "ArrowLeft") { previous(); }
            if (key === " ") { toggleHideAnswer(); }
            if (key === "Enter") { toggleHideAnswer(); }
        }
    }

    useEffect(() => {
        // Attach event listeners when the component mounts
        document.addEventListener('keydown', handleKeyDown);
        // Clean up event listeners when the component unmounts
        return () => { document.removeEventListener('keydown', handleKeyDown); };
    });

    const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false);
    function isUpdateOpenHandler(): void { setIsUpdateOpen(!isUpdateOpen); }

    // Image Src ----------------------------------------------------------------------------------------
    const [imageSrc, setImageSrc] = useState<string>(loadingImg);

    async function getImage(imageName: string) {
        try {
            let srcImage = await imageAndAudio_localMemory.getImage(userUuid, imageName);
            setImageSrc(srcImage);
        }
        catch (err: any) {
            errorMessages(err);
        }
    }

    // Audio Src -----------------------------------------------------------------------------------------
    const [audioSrc, setAudioSrc] = useState<string>(null);

    // Word
    const [loadingWordAudio, setLoadingWordAudio] = useState<boolean>(false);

    async function getAudioWord(audioName: string) {
        try {
            setLoadingWordAudio(true);
            const src = await imageAndAudio_localMemory.getAudio(userUuid, audioName);
            setAudioSrc(src);
            setLoadingWordAudio(false);
        }
        catch (err: any) {
            errorMessages(err);
        }
    }

    // Sentence
    const [loadingSentenceAudio, setLoadingSentenceAudio] = useState<boolean>(false);

    async function getAudioSentence(audioName: string) {
        try {
            setLoadingSentenceAudio(true);
            const src = await imageAndAudio_localMemory.getAudio(userUuid, audioName);
            setAudioSrc(src);
            setLoadingSentenceAudio(false);
        }
        catch (err: any) {
            errorMessages(err);
        }
    }

    // Update word --------------------------------------------------------------------------------
    async function updateWord(): Promise<void> {
        const newWord = props.words_localMemory.words.find(function (word) {
            return word.uuid === words[currentIndex].uuid;
        });
        words[currentIndex] = newWord;
        set_word(newWord.word);
        set_wordTranslation(newWord.wordTranslation);
        set_sentence(newWord.sentence);
        set_sentenceTranslation(newWord.sentenceTranslation);

        getImage(newWord.picture);
        getAudioWord(newWord.speechWord);
        getAudioSentence(newWord.speechSentence);
    }

    // Adding a new word by selecting it ----------------------------------------------------------------
    const [is_selectedText_tooltipOpen, setIs_selectedText_tooltipOpen] = useState<boolean>(false)
    const [selectedText, setSelectedText] = useState<string>('');

    const handleSelect = () => {
        const selectedText = document.getSelection().toString().trim();
        setSelectedText(selectedText);
        setIs_selectedText_tooltipOpen(true);
    };

    // ----------------------------------------------------------------------------------------------
    return (
        <div className="Test"
            style={{
                width: widthOfWindow - 3,
                height: props.heightOfContent ? props.heightOfContent - 1 : '0',
                border: `1px solid ${loadingScore || sumOfWords === 0 ? 'rgb(21, 21, 21)' : score === 1 ? 'red' : score === 2 ? '#FFC439' : 'rgb(82, 255, 47)'}`
            }}>

            {/* Audio ------------------------------------------------------ */}
            <audio
                src={audioSrc}
                // autoPlay
                ref={audioRef}
                onEnded={end}
                onCanPlay={() => setPlaybackRate()}
            />

            {/* Word ------------------------------------------------------ */}
            <div id="wordContainer"
                style={{ width: widthOfWindow - 3 }}>

                <div id="wordAndTranslationDiv"
                    style={{ width: widthOfWindow - 32 }}>

                    <div dir="auto">
                        {word}
                        {loadingWordAudio && <div className="loading"><img src={loadingImg} /></div>}
                        {word && <i className="fa-solid fa-volume-low speechIcon" onClick={speechWord}></i>}
                    </div>

                    <div dir="auto"
                        className={`translationDiv ${hideAnswerClass}`}>
                        {wordTranslation}
                    </div>

                </div>

                <div id="sentenceAndTranslationDiv"
                    style={{ width: widthOfWindow - 32 }}
                    // Adding a new word by selecting it
                    data-tooltip-id="selected-text-tooltip"
                    data-tooltip-place="bottom"
                >
                    <div>
                        <div dir="auto"
                            onMouseUp={handleSelect}
                            onTouchEnd={handleSelect}
                            onSelect={handleSelect}
                        >
                            {sentence}
                            {loadingSentenceAudio && <div className="loading"><img src={loadingImg} /></div>}
                            {sentence && <i className="fa-solid fa-volume-low speechIcon" onClick={speechSentence}></i>}
                        </div>

                        <div dir="auto"
                            className={`translationDiv ${hideAnswerClass}`}>
                            {sentenceTranslation}
                        </div>
                    </div>

                </div>
                <Tooltip id="selected-text-tooltip"
                    isOpen={is_selectedText_tooltipOpen}
                    clickable={true}
                    opacity='1'
                >
                    {
                        is_selectedText_tooltipOpen &&
                        <TooltipSelectedText
                            selectedText={selectedText}
                            setIs_selectedText_tooltipOpen={setIs_selectedText_tooltipOpen}
                        />
                    }
                </Tooltip>

                <div id="imgDiv" onClick={toggleHideAnswer} {...handlers}
                    style={{ width: widthOfWindow - 16 }}>
                    {
                        !hideAnswer && words[currentIndex]?.picture
                            ?
                            <img src={imageSrc} />
                            :
                            <i
                                className="fa-solid fa-arrows-up-down-left-right"
                                style={hideAnswer ?
                                    {
                                        color: loadingScore || sumOfWords === 0 ? 'rgb(55, 55, 55)' :
                                            score === 1 ? 'red' : score === 2 ? '#FFC439' : 'rgb(82, 255, 47)'
                                    }
                                    :
                                    {
                                        borderRadius: '45px',
                                        padding: '5px',
                                        color: 'rgb(21, 21, 21)',
                                        background: loadingScore || sumOfWords === 0 ? 'rgb(55, 55, 55)' :
                                            score === 1 ? 'red' : score === 2 ? '#FFC439' : 'rgb(82, 255, 47)'
                                    }
                                }
                            ></i>
                    }
                </div>

                {
                    displayScore && <div id="displayScoreDiv"
                        style={{
                            color: 'black',
                            backgroundColor: !loadingScore && (score === 1 ? 'red' : score === 2 ? '#FFC439' : 'rgb(82, 255, 47)')
                        }}
                    >
                        {
                            loadingScore ?
                                <img src={loadingWhiteImg} />
                                :
                                score
                        }
                    </div>
                }
            </div>

            {/* Controllers ------------------------------------------------------ */}
            <div id="controllersDiv">

                <div id="sumMutedScoreDiv">

                    <TooltipKeyboardShortcuts mode="test" />

                    <div id="sumDiv">{currentIndex + 1}/{sumOfWords}</div>

                    <div
                        id="scoreDiv"
                        className={loadingScoreClass}
                        style={{
                            color: score === 1 ? 'red' : score === 2 ? '#FFC439' : 'rgb(82, 255, 47)'
                        }}
                    >
                        {score}
                    </div>

                    <div id="updateDiv">
                        {words[currentIndex] &&
                            <Update
                                wordToUpdate={words[currentIndex]}
                                words_localMemory={props.words_localMemory}
                                preferences_localMemory={props.preferences_localMemory}
                                userUuid={props.userUuid}
                                ifFixImagePosition={false}
                                updateWord={updateWord}
                                isUpdateOpen={isUpdateOpenHandler}
                            />
                        }
                    </div>

                    <div id="MutedDiv"
                        data-tooltip-id="muteTooltip" data-tooltip-content={muted ? "Unmute" : "Mute"}
                    >
                        {muted ?
                            <i className="fa-solid fa-volume-xmark muteIcon" onClick={toggleMuted}></i>
                            :
                            <i className="fa-solid fa-volume-low muteIcon" onClick={toggleMuted}></i>
                        }
                    </div>
                </div>

                <div id="buttonsDiv">
                    <i
                        className="fa-solid fa-circle-arrow-left"
                        onClick={previous}
                        data-tooltip-id="previousTooltip" data-tooltip-content="Previous"
                    ></i>

                    <div>
                        <i
                            className="fa-solid fa-circle-minus"
                            onClick={() => minusScore(words[currentIndex].uuid)}
                            data-tooltip-id="minusScoreTooltip" data-tooltip-content="Decrease the score"
                        ></i>

                        <div id="hideAnswerDiv">
                            {hideAnswer ?
                                <i
                                    className="fa-solid fa-eye-slash"
                                    onClick={toggleHideAnswer}
                                    data-tooltip-id="hideAnswerTooltip" data-tooltip-content="Reveal"
                                ></i>
                                :
                                <i
                                    className="fa-solid fa-eye"
                                    onClick={toggleHideAnswer}
                                    data-tooltip-id="hideAnswerTooltip" data-tooltip-content="Hide"
                                ></i>
                            }
                        </div>

                        <i
                            className="fa-solid fa-circle-plus"
                            onClick={() => plusScore(words[currentIndex].uuid)}
                            data-tooltip-id="plusScoreTooltip" data-tooltip-content="Increase the score"
                        ></i>
                    </div>

                    <i
                        className="fa-solid fa-circle-arrow-right"
                        onClick={next}
                        data-tooltip-id="nextTooltip" data-tooltip-content="Next"
                    ></i>

                </div>

            </div>

        </div>
    );
}

export default observer(Test);