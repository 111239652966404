import { useEffect, useState } from "react";
import "./TooltipSelectedText.css";
import notify from "../../../5_Helpers/NotifyMessages";
import errorMessages from "../../../5_Helpers/ErrorMessages";
import ai_webAccess from "../../../2_WebAccess/AI_webAccess";
import preferences_localMemory from "../../../3_LocalMemory/Preferences_localMemory";
import words_localMemory from "../../../3_LocalMemory/Words_localMemory";

interface TooltipSelectedTextProps {
    selectedText: string;
    setIs_selectedText_tooltipOpen: (isOpen: boolean) => void;
}

function TooltipSelectedText(props: TooltipSelectedTextProps): JSX.Element {
    const preferences = preferences_localMemory.preferences;
    const [selectedText, setSelectedText] = useState<string>('');

    // Adding a new word ---------------------------------------------------------------
    const [saveWord_isLoading, setSaveWord_isLoading] = useState<boolean>(false);
    const [isTextLong, setIsTextLong] = useState<boolean>(false);

    async function saveWord(text: string): Promise<void> {
        try {
            if (saveWord_isLoading) return;

            if (words_localMemory.isExists(text)) {
                notify.error("The word already exists.");
                return;
            }

            setSaveWord_isLoading(true);

            let newWord: any = {
                word: text,
                wordTranslation: '',
                sentence: '',
                sentenceTranslation: '',
                language: preferences.speechLanguage,
                voice: preferences.speechVoice,
                highQuality: preferences.highQuality,
                myImage: null,
                category: "No"
            };

            const message = await words_localMemory.add(newWord);
            words_localMemory.updateCount();

            props.setIs_selectedText_tooltipOpen(false);
            setSaveWord_isLoading(false);

            notify.success(message);
        }
        catch (error: any) {
            props.setIs_selectedText_tooltipOpen(false);
            setSaveWord_isLoading(false);
            errorMessages(error);
        }
    }

    // Translate ---------------------------------------------------------------
    const [translation, setTranslation] = useState<string>('...');

    async function translate(text: string): Promise<void> {
        const textTranslation = await ai_webAccess.translation(preferences.sourceLanguage, preferences.targetLanguage, text);
        setTranslation(textTranslation);
    };

    // ---------------------------------------------------------------
    useEffect(() => {

        if (props.selectedText == '') {
            props.setIs_selectedText_tooltipOpen(false);
            return;
        }

        setIsTextLong(false);
        // Checking that there are no 3 spaces in the text.
        let regex = /\s/g;
        let spaces = selectedText.match(regex);
        if (spaces && spaces.length >= 3)
            setIsTextLong(true);

        setSelectedText(props.selectedText);
        props.setIs_selectedText_tooltipOpen(true);

        if (props.selectedText !== selectedText)
            translate(props.selectedText);
    });

    // ---------------------------------------------------------------
    return (
        <div className="TooltipSelectedText">
            {!isTextLong &&
                <div
                    onClick={() => saveWord(selectedText)}>
                    {selectedText}&nbsp;
                    {saveWord_isLoading ?
                        <i className="fa-regular fa-floppy-disk fa-flip"></i> :
                        <i className="fa-regular fa-floppy-disk"
                            style={words_localMemory.isExists(selectedText) ? { color: 'red' } : {}}
                        ></i>}
                </div>
            }
            <a href={`https://translate.google.com/?sl=${preferences.sourceLanguage}&tl=${preferences.targetLanguage}&text=${selectedText}&op=translate`}
                target="_blank" style={{ color: 'black' }}>
                {translation}&nbsp;
                <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </a>
        </div>
    );
}

export default TooltipSelectedText;
