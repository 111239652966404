import "./Page404.css";

function Page404(): JSX.Element {
    return (
        <div className="Page404">

            <h1>404</h1>
			<p>Page Not Found</p>
            
        </div>
    );
}

export default Page404;
