import axios from 'axios';
import urls from './Urls';
import user_localMemory from '../3_LocalMemory/User_localMemory';

class Payment_webAccess {

    public async createSubscription_step1(): Promise<any> {
        const response = await fetch(urls.payment.createSubscription_step1, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + user_localMemory.token
            },
            body: JSON.stringify({ userAction: "SUBSCRIBE_NOW" }),
        });
        const data = await response.json();
        return data;
    }
    
    public async createSubscription_step2(subscriptionID: string): Promise<number> {
        const response = await fetch(`${urls.payment.createSubscription_step2}${subscriptionID}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + user_localMemory.token
            }
        });
        return response.status;
    }

    public async cancelSubscription(): Promise<number> {
        const response = await axios.post<string>(`${urls.payment.cancelSubscription}`);
        return response.status;
    }
}

const payment_webAccess = new Payment_webAccess();

export default payment_webAccess;