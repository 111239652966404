import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import imageAndAudio_localMemory from '../../../3_LocalMemory/imageAndAudio_localMemory';
import errorMessages from '../../../5_Helpers/ErrorMessages';
import user_localMemory from '../../../3_LocalMemory/User_localMemory';
import loadingImg from "../../../4_Assets/Images/loading.gif";

interface LazyLoadedImageProps {
    src: string;
    alt: string;
}

const LazyLoadedImage: React.FC<LazyLoadedImageProps> = ({ src, alt }) => {

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    // ------------------------------------------------
    const [imageSrc, setImageSrc] = useState<string>(loadingImg);

    async function getImage() {
        try {
            let srcImage = await imageAndAudio_localMemory.getImage(user_localMemory.user.uuid, src);
            setImageSrc(srcImage);
        }
        catch (err: any) {
            errorMessages(err);
        }
    }

    useEffect(() => {
        if (inView) getImage();
    }, [inView]);

    // ------------------------------------------------
    return (
        <img
            ref={ref}
            src={inView && imageSrc !== loadingImg ? imageSrc : loadingImg}
            alt={alt}
            style={imageSrc === loadingImg ? {
                border: '1px solid transparent'
            } : {}}
        />
    );
};

export default LazyLoadedImage;