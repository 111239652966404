import { SyntheticEvent, useState } from "react";
import VoiceOptions from "../Speech/VoiceOptions";
import "./LanguageSelector.css";
import { languagesAndVoices } from "../Speech/languagesAndVoices";
import { useForm } from "react-hook-form";
import PreferencesModel from "../../../../1_Models/PreferencesModel";
import errorMessages from "../../../../5_Helpers/ErrorMessages";
import LanguageOptions_speech from "../Speech/LanguageOptions_speech";
import LanguageOptions_translation from "../Translation/LanguageOptions_translation";
import { Preferences_localMemory } from "../../../../3_LocalMemory/Preferences_localMemory";
import notify from "../../../../5_Helpers/NotifyMessages";
import Overlay from "../../Overlay/Overlay";
import { Tooltip } from "react-tooltip";

interface LanguageSelectorProps {
    preferences_localMemory: Preferences_localMemory;
}

function LanguageSelector(props: LanguageSelectorProps): JSX.Element {

    const [loading, setLoading] = useState<boolean>(false);

    // Use Form --------------------------------
    const { register, handleSubmit, reset } = useForm<PreferencesModel>({ defaultValues: props.preferences_localMemory.preferences });

    async function submit(preferences: PreferencesModel) {
        try {
            const oldPreferences = props.preferences_localMemory.preferences;
            if (
                preferences.speechLanguage === oldPreferences.speechLanguage &&
                preferences.speechVoice === oldPreferences.speechVoice &&
                preferences.sourceLanguage === oldPreferences.sourceLanguage &&
                preferences.targetLanguage === oldPreferences.targetLanguage
            ) return;

            setLoading(true);
            let newPreferences = {
                ...oldPreferences,
                speechLanguage: preferences.speechLanguage,
                speechVoice: preferences.speechVoice,
                sourceLanguage: preferences.sourceLanguage,
                targetLanguage: preferences.targetLanguage
            };
            const message = await props.preferences_localMemory.update(newPreferences);
            notify.success(message);
            setLoading(false);
        }
        catch (error: any) {
            errorMessages(error);
            setLoading(false);
        }
    }

    // Change Voice Options --------------------------------
    const [selectedSpeechLanguage, setSelectedSpeechLanguage] =
        useState<string>(props.preferences_localMemory.preferences?.speechLanguage);

    function changeVoiceOptionsHandler(args: SyntheticEvent) {
        let selectedSpeechLanguage = (args.target as HTMLSelectElement).value;
        setSelectedSpeechLanguage(selectedSpeechLanguage);

        // Default Voice
        let defaultVoice = "";
        if (selectedSpeechLanguage === props.preferences_localMemory.preferences.speechLanguage) {
            defaultVoice = props.preferences_localMemory.preferences.speechVoice;
        }
        else {
            let index = languagesAndVoices.findIndex(item => item.language.value === selectedSpeechLanguage);
            defaultVoice = languagesAndVoices[index].voices[0].value;
        }
        reset({
            speechVoice: defaultVoice
        });
    }

    // Overlay --------------------------------
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggleOverlay = () => { setIsOpen(!isOpen); };

    // --------------------------------
    return (
        <div className="LanguageSelector">

            <i className="fa-solid fa-gear" onClick={toggleOverlay}
                data-tooltip-id="settings_tooltip" data-tooltip-content="Language Settings"
            ></i>
            <Tooltip id="settings_tooltip" />

            <Overlay isOpen={isOpen} onClose={toggleOverlay}>

                <i className="fa-solid fa-circle-xmark" onClick={toggleOverlay}
                    data-tooltip-id="close_tooltip" data-tooltip-content="Close"
                ></i>
                <Tooltip id="close_tooltip" />

                <form onSubmit={handleSubmit(submit)}>

                    <h3>Language Settings</h3>

                    <div id="translationDiv">
                        <h4>Translation</h4>
                        <div>
                            <label htmlFor="sourceLanguage_select">From</label>
                            <select
                                id="sourceLanguage_select"
                                {...register("sourceLanguage")}>
                                <LanguageOptions_translation />
                            </select>
                        </div>

                        <div>
                            <label htmlFor="targetLanguage_select">To</label>
                            <select
                                id="targetLanguage_select"
                                {...register("targetLanguage")}>
                                <LanguageOptions_translation />
                            </select>
                        </div>
                    </div>

                    <div id="SpeechDiv">
                        <h4>Speech</h4>
                        <div>
                            <label htmlFor="speechLanguage_select">Language</label>
                            <select
                                id="speechLanguage_select"
                                onChangeCapture={changeVoiceOptionsHandler}
                                {...register("speechLanguage")}>
                                <LanguageOptions_speech />
                            </select>
                        </div>

                        <div>
                            <label htmlFor="speechVoice_select">Voice</label>
                            <select
                                id="speechVoice_select"
                                {...register("speechVoice")}>
                                <VoiceOptions language={selectedSpeechLanguage} />
                            </select>
                        </div>
                    </div>

                    {
                        loading ?
                            <button className="save">Save <i className="fa-solid fa-floppy-disk fa-flip"></i></button>
                            :
                            <button className="save">Save <i className="fa-solid fa-floppy-disk"></i></button>
                    }

                </form>
            </Overlay>
        </div>
    );
}

export default LanguageSelector;
