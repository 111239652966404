import "./MediaPlayer.css";
import { observer } from "mobx-react";
import { useEffect, RefObject, useRef, useState } from "react";
import { Words_localMemory } from "../../../../3_LocalMemory/Words_localMemory";
import { Preferences_localMemory } from "../../../../3_LocalMemory/Preferences_localMemory";
import WordModel from "../../../../1_Models/WordModel";
import { useSwipeable } from "react-swipeable";
import { useForm } from "react-hook-form";
import useWindowDimensions from "../../../../5_Helpers/UseWindowDimensions";
import imageAndAudio_localMemory from "../../../../3_LocalMemory/imageAndAudio_localMemory";
import loadingImg from "../../../../4_Assets/Images/loading.gif";
import errorMessages from "../../../../5_Helpers/ErrorMessages";
import TooltipKeyboardShortcuts from "../../../SharedArea/TooltipKeyboardShortcuts/TooltipKeyboardShortcuts";
import { Tooltip } from "react-tooltip";
import TooltipSelectedText from "../../../SharedArea/TooltipSelectedText/TooltipSelectedText";
import loadingWhiteImg from "../../../../4_Assets/Images/loading_white.gif";
import Update from "../../3_List/Update/Update";

interface RepeatModel {
    repeat: number;
}

interface SleepModel {
    sleep: number;
}

interface MediaPlayerProps {
    words_localMemory: Words_localMemory;
    preferences_localMemory: Preferences_localMemory;
    userUuid: string;
    heightOfContent: number;
}

function MediaPlayer(props: MediaPlayerProps): JSX.Element {
    const userUuid = props.userUuid;
    const [words, setWords] = useState<WordModel[]>([]);
    const [sumOfWords, setSumOfWords] = useState<number>(0);

    const [word, set_word] = useState<string>();
    const [wordTranslation, set_wordTranslation] = useState<string>();
    const [sentence, set_sentence] = useState<string>();
    const [sentenceTranslation, set_sentenceTranslation] = useState<string>();

    useEffect(() => {
        setWords(props.words_localMemory.words.filter(w => w.display === true));
    }, []);

    useEffect(() => {
        setWords(props.words_localMemory.words.filter(w => w.display === true));
        setIsSentence(true);
        play();
    }, [props.words_localMemory.displaySettingsModel]);

    useEffect(() => {
        setWord(0);
        setSumOfWords(props.words_localMemory.count);
    }, [words]);

    // localStorage -----------------------------------------------------------------------------
    const localStorage_repeat = +JSON.parse(localStorage.getItem("mediaPlayer_repeat"));
    const localStorage_sleep = +JSON.parse(localStorage.getItem("mediaPlayer_sleep"));

    useEffect(() => {
        if (localStorage_repeat) {
            resetRepeat({ repeat: localStorage_repeat });
            submitRepeat({ repeat: localStorage_repeat });
        }
        if (localStorage_sleep) {
            resetSleep({ sleep: localStorage_sleep });
            submitSleep({ sleep: localStorage_sleep });
        }
    }, []);

    function resetAllSettings() {
        resetRepeat({ repeat: 1 });
        resetSleep({ sleep: 0 });

        submitRepeat({ repeat: 1 });
        submitSleep({ sleep: 0 });

        setTimeout(() => {
            localStorage.removeItem("mediaPlayer_repeat");
            localStorage.removeItem("mediaPlayer_sleep");
        }, 3000);
    }

    // Forms ------------------------------------------------------------------------------------
    // Repeat
    const { register: registerRepeat, handleSubmit: handleSubmitRepeat, reset: resetRepeat } = useForm<RepeatModel>(
        { defaultValues: { repeat: 1 } }
    );
    const [sumRepeat, setSumRepeat] = useState<number>(1);
    const [leftRepeat, setLeftRepeat] = useState<number>(sumRepeat);

    function submitRepeat(repeat: RepeatModel): void {
        setLeftRepeat(0);
        if (repeat.repeat && (repeat.repeat < 1 || repeat.repeat > 999)) {
            resetRepeat({ repeat: 1 });
            setSumRepeat(1);
            return;
        }
        setSumRepeat(repeat.repeat);
        localStorage.setItem('mediaPlayer_repeat', JSON.stringify(repeat.repeat));
    }

    // Sleep
    const { register: registerSleep, handleSubmit: handleSubmitSleep, reset: resetSleep } = useForm<SleepModel>(
        { defaultValues: { sleep: 0 } }
    );
    const [sleepTimeout, setSleepTimeout] = useState<NodeJS.Timeout>(null);
    const minute = 1000 * 60;

    function submitSleep(sleep: SleepModel): void {
        if (sleep.sleep && (sleep.sleep < 0 || sleep.sleep > 999)) {
            resetSleep({ sleep: 1 });
            sleep.sleep = 1;
        }
        // if (!isPlay) { play() };
        if (sleepTimeout) { clearTimeout(sleepTimeout); }
        if (sleep.sleep > 0) { setSleepTimeout(setTimeout(stop, sleep.sleep * minute)) };
        localStorage.setItem('mediaPlayer_sleep', JSON.stringify(sleep.sleep));
    }

    // tooltip
    const [settings_isOpen, setSettings_isOpen] = useState<boolean>(false);
    function toggle_settings_isOpen() { setSettings_isOpen(!settings_isOpen) }

    // Select the text in the input field
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select();
    };

    // Audio ------------------------------------------------------------------------------------
    const audioRef: RefObject<HTMLAudioElement> = useRef();

    const [currentWord, setCurrentWord] = useState<WordModel>();
    const [currentIndex, setCurrentIndex] = useState<number>();
    const [isSentence, setIsSentence] = useState<boolean>(false);
    const score = words[currentIndex]?.score;

    function setWord(index: number): void {
        setCurrentWord(words[index]);
        setCurrentIndex(index);
        getImage(words[index]?.picture);
        getAudioWord(words[index]?.speechWord);

        set_word(words[index]?.word);
        set_wordTranslation(words[index]?.wordTranslation);
        set_sentence(words[index]?.sentence);
        set_sentenceTranslation(words[index]?.sentenceTranslation);

        // Saving the audio of the next word. 
        const nextIndex = index === words.length - 1 ? 0 : index + 1;
        imageAndAudio_localMemory.saveImageAndAudio(userUuid, words[nextIndex]);
    }

    // -----------------------------
    function play(): void {
        if (currentWord) {
            audioRef.current.play();
            setIsPlay(true);
        }
    }

    function stop(): void {
        audioRef.current.pause();
        setIsPlay(false);
    }

    function next(): void {
        currentIndex < words.length - 1 ? setWord(currentIndex + 1) : setWord(0);
        setLeftRepeat(sumRepeat);
        setIsSentence(true);
        setIsPlay(true);
        setIs_selectedText_tooltipOpen(false);
    }

    function previous(): void {
        currentIndex != 0 ? setWord(currentIndex - 1) : setWord(words.length - 1);
        setLeftRepeat(sumRepeat);
        setIsSentence(true);
        setIsPlay(true);
        setIs_selectedText_tooltipOpen(false);
    }

    function end(): void {
        if (isSentence && currentWord?.speechSentence) {
            getAudioSentence(currentWord?.speechSentence);
            setIsSentence(false);
        }
        else {
            if (leftRepeat > 1) {
                if (currentWord?.sentence)
                    getAudioWord(currentWord?.speechWord);
                else play();
                setLeftRepeat(leftRepeat - 1);
            }
            else {
                currentIndex < words.length - 1 ? setWord(currentIndex + 1) : setWord(0);
                setLeftRepeat(sumRepeat);
            }
            setIsSentence(true);
        }
    }

    function setPlaybackRate(): void {
        audioRef.current.playbackRate = props.preferences_localMemory.preferences?.rateSpeech;
    }

    // Swipe ------------------------------------------------------------------------------------------
    // https://www.npmjs.com/package/react-swipeable
    const handlers = useSwipeable({
        onSwipedLeft: () => next(),
        onSwipedRight: () => previous(),
        onSwipedUp: () => plusScore(words[currentIndex].uuid),
        onSwipedDown: () => minusScore(words[currentIndex].uuid),
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    // Replacing the play/stop icon+play() -------------------------------------------------------------
    const [isPlay, setIsPlay] = useState<boolean>(true);
    function togglePlay(): void { isPlay ? stop() : play(); }

    // Hiding the translation --------------------------------------------------------------------------
    const [hideTranslation, setHideTranslation] = useState<boolean>(false);
    function toggleHideTranslation(): void { setHideTranslation(!hideTranslation); }
    const hideTranslationClass = hideTranslation ? 'hideTranslation' : '';

    // Listening to the keyboard keys ------------------------------------------------------------------
    const handleKeyDown = (event: KeyboardEvent) => {
        if (!isUpdateOpen) {
            const key = event.key;
            if (key === " ") { isPlay ? stop() : play(); }
            if (key === "ArrowRight") { next(); }
            if (key === "ArrowLeft") { previous(); }
            if (key === "Enter") { toggleHideTranslation(); }
            if (key === "ArrowUp") { plusScore(words[currentIndex].uuid); }
            if (key === "ArrowDown") { minusScore(words[currentIndex].uuid); }
        }
    }

    useEffect(() => {
        // Attach event listeners when the component mounts
        document.addEventListener('keydown', handleKeyDown);
        // Clean up event listeners when the component unmounts
        return () => { document.removeEventListener('keydown', handleKeyDown); };
    });

    const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false);
    function isUpdateOpenHandler(): void { setIsUpdateOpen(!isUpdateOpen); }

    useEffect(() => {
        stop();
    }, [isUpdateOpen]);

    // Width --------------------------------------------------------------------------------------------
    const { width: widthOfWindow } = useWindowDimensions();

    // Image Src -------------------------------------------------------------------------------------------
    const [imageSrc, setImageSrc] = useState<string>(loadingImg);

    async function getImage(imageName: string) {
        try {
            let srcImage = await imageAndAudio_localMemory.getImage(userUuid, imageName);
            setImageSrc(srcImage);
        }
        catch (err: any) {
            errorMessages(err);
        }
    }

    // Audio Src -------------------------------------------------------
    const [audioSrc, setAudioSrc] = useState<string>(null);

    // Word
    const [loadingWordAudio, setLoadingWordAudio] = useState<boolean>(false);

    async function getAudioWord(audioName: string) {
        try {
            setLoadingWordAudio(true);
            const src = await imageAndAudio_localMemory.getAudio(userUuid, audioName);
            setAudioSrc(src);
            setLoadingWordAudio(false);
        }
        catch (err: any) {
            errorMessages(err);
        }
    }

    // Sentence
    const [loadingSentenceAudio, setLoadingSentenceAudio] = useState<boolean>(false);

    async function getAudioSentence(audioName: string) {
        try {
            setLoadingSentenceAudio(true);
            const src = await imageAndAudio_localMemory.getAudio(userUuid, audioName);
            setAudioSrc(src);
            setLoadingSentenceAudio(false);
        }
        catch (err: any) {
            errorMessages(err);
        }
    }

    // Adding a new word by selecting it ------------------------------------------------------------
    const [is_selectedText_tooltipOpen, setIs_selectedText_tooltipOpen] = useState<boolean>(false)
    const [selectedText, setSelectedText] = useState<string>('');

    const handleSelect = () => {
        const selectedText = document.getSelection().toString().trim();
        setSelectedText(selectedText);
        setIs_selectedText_tooltipOpen(true);
    };

    // Updating Score -------------------------------------------------------------------------
    const [loadingScore, setLoadingScore] = useState<boolean>(false);

    // Display Score
    const [displayScore, setDisplayScore] = useState<boolean>(false);
    const [scoreTimeout, setScoreTimeout] = useState<NodeJS.Timeout>();

    async function plusScore(uuid: string): Promise<void> {
        clearTimeout(scoreTimeout);
        setDisplayScore(true);

        if (score < 3) {
            setLoadingScore(true);
            await props.words_localMemory.updateScore(uuid, score + 1);
            setLoadingScore(false);
        }

        const timeout = setTimeout(() => { setDisplayScore(false); }, 500);
        setScoreTimeout(timeout);
    }

    async function minusScore(uuid: string): Promise<void> {
        clearTimeout(scoreTimeout);
        setDisplayScore(true);

        if (score > 1) {
            setLoadingScore(true);
            await props.words_localMemory.updateScore(uuid, score - 1);
            setLoadingScore(false);
        }

        const timeout = setTimeout(() => { setDisplayScore(false); }, 500);
        setScoreTimeout(timeout);
    }

    // Update word --------------------------------------------------------------------------------
    async function updateWord(): Promise<void> {
        const newWord = props.words_localMemory.words.find(function (word) {
            return word.uuid === words[currentIndex].uuid;
        });
        words[currentIndex] = newWord;
        set_word(newWord.word);
        set_wordTranslation(newWord.wordTranslation);
        set_sentence(newWord.sentence);
        set_sentenceTranslation(newWord.sentenceTranslation);
        setCurrentWord(newWord);

        getImage(newWord.picture);
        getAudioWord(newWord.speechWord);
        getAudioSentence(newWord.speechSentence);
    }

    //  Handle clicks on "imgDiv" --------------------------------------------------------------------------------
    const [clickTimeout, setClickTimeout] = useState<number | NodeJS.Timeout | null>(null);

    const handleClicks = () => {
        if (clickTimeout !== null && typeof clickTimeout === 'number') {
            togglePlay();
            clearTimeout(clickTimeout);
            setClickTimeout(null);
        } else {
            const timeoutId = setTimeout(() => {
                toggleHideTranslation();
                clearTimeout(timeoutId);
                setClickTimeout(null);
            }, 400);
            setClickTimeout(timeoutId);
        }
    };

    useEffect(() => {
        return () => {
            // Cleanup the timeout when the component unmounts
            if (clickTimeout !== null && typeof clickTimeout === 'number') {
                clearTimeout(clickTimeout);
            }
        };
    }, [clickTimeout]);

    // -------------------------------------------------------------------------------------------
    return (
        <div className="MediaPlayer">
            {/* audio ------------------------------------------------------ */}
            <audio
                src={audioSrc}
                autoPlay
                ref={audioRef}
                onEnded={end}
                onCanPlay={() => setPlaybackRate()}
                onError={() => setIsPlay(false)}
                onPlay={() => setIsPlay(true)}
            />

            {/* Media Player ------------------------------------------------------ */}
            <div id="mediaPlayerDiv"
                style={{
                    width: widthOfWindow - 4,
                    height: props.heightOfContent ? props.heightOfContent - 0 : 0,
                    border: `1px solid 
                    ${loadingScore || sumOfWords === 0 ?
                            'rgb(21, 21, 21)' :
                            score === 1 ? 'red' : score === 2 ? '#FFC439' : 'rgb(82, 255, 47)'}`
                }}>
                {/* word ------------------------------------------------------ */}
                <div id="wordContainer" onClick={() => setSettings_isOpen(false)}>

                    <div id="wordAndTranslationDiv" style={{ width: widthOfWindow - 30 }}>

                        <div dir="auto">
                            {word}
                            {loadingWordAudio && <div className="loading"><img src={loadingImg} /></div>}
                        </div>

                        <div dir="auto"
                            className={`translationDiv ${hideTranslationClass}`}>
                            {wordTranslation}
                        </div>

                    </div>

                    <div id="sentenceAndTranslationDiv"
                        style={{ width: widthOfWindow - 30 }}
                    >
                        <div>
                            <div dir="auto"
                                onMouseUp={handleSelect}
                                onTouchEnd={handleSelect}
                                onSelect={handleSelect}
                            >
                                {sentence}
                                {loadingSentenceAudio && <div className="loading"><img src={loadingImg} /></div>}

                                <Tooltip id="selected-text-tooltip"
                                    isOpen={is_selectedText_tooltipOpen}
                                    clickable={true}
                                    opacity='1'
                                >
                                    {
                                        is_selectedText_tooltipOpen &&
                                        <TooltipSelectedText
                                            selectedText={selectedText}
                                            setIs_selectedText_tooltipOpen={setIs_selectedText_tooltipOpen}
                                        />
                                    }
                                </Tooltip>
                            </div>

                            <div dir="auto"
                                className={`translationDiv ${hideTranslationClass}`}>
                                {sentenceTranslation}
                            </div>
                        </div>

                    </div>

                    <div id="imgDiv" style={{ width: widthOfWindow - 14 }} onClick={handleClicks} {...handlers}
                        // Adding a new word by selecting it
                        data-tooltip-id="selected-text-tooltip"
                        data-tooltip-place="top"
                    >
                        {
                            !hideTranslation && currentWord?.picture
                                ?
                                <img src={imageSrc} />
                                :
                                <i
                                    className="fa-solid fa-arrows-up-down-left-right"
                                    style={hideTranslation ?
                                        {
                                            color: loadingScore || sumOfWords === 0 ? 'rgb(55, 55, 55)' :
                                                score === 1 ? 'red' : score === 2 ? '#FFC439' : 'rgb(82, 255, 47)'
                                        }
                                        :
                                        {
                                            borderRadius: '45px',
                                            padding: '5px',
                                            color: 'rgb(21, 21, 21)',
                                            background: loadingScore || sumOfWords === 0 ? 'rgb(55, 55, 55)' :
                                                score === 1 ? 'red' : score === 2 ? '#FFC439' : 'rgb(82, 255, 47)'
                                        }
                                    }
                                ></i>
                        }

                    </div>

                    {
                        displayScore && <div id="displayScoreDiv"
                            style={{
                                color: 'black',
                                backgroundColor: !loadingScore && (score === 1 ? 'red' : score === 2 ? '#FFC439' : 'rgb(82, 255, 47)')
                            }}
                            data-tooltip-id="displayScore_Tooltip"
                        >
                            {
                                loadingScore ?
                                    <img src={loadingWhiteImg} />
                                    :
                                    score
                            }
                        </div>
                    }

                </div>

                {/* controllers ------------------------------------------------------ */}
                <div id="controllersDiv">

                    {/* settings ------------------------------------------------------ */}
                    <i
                        className="fa-solid fa-gear"
                        data-tooltip-id="settings-tooltip"
                        onClick={toggle_settings_isOpen}
                        style={{ color: settings_isOpen ? '#FFC439' : '#6d6d6d' }}
                    ></i>
                    <Tooltip id="settings-tooltip"
                        isOpen={settings_isOpen}
                        clickable={true}
                        opacity='1'
                    >
                        <div id="settings">
                            <i className="fa-solid fa-circle-xmark" onClick={toggle_settings_isOpen}></i>

                            {/* Repeat */}
                            <form onChange={handleSubmitRepeat(submitRepeat)}>
                                <label> Repeat  </label>
                                <input type="number" {...registerRepeat("repeat")} onFocus={handleFocus}></input> times
                            </form>

                            {/* Sleep */}
                            <form onChange={handleSubmitSleep(submitSleep)}>
                                <label> Sleep after </label>
                                <input type="number" {...registerSleep("sleep")} onFocus={handleFocus}></input> minutes
                            </form>

                            <div id="resetSettings_div">
                                <span
                                    id="resetSettings_span"
                                    onClick={resetAllSettings}
                                >
                                    Reset <i className="fa-solid fa-arrow-rotate-left"></i>
                                </span>
                            </div>
                        </div>
                    </Tooltip>

                    {/* Sum */}
                    <div id="sumDiv">{sumOfWords === 0 ? '0' : currentIndex ? currentIndex + 1 : '1'}/{sumOfWords}</div>

                    {/* Controllers */}
                    <div id="playDiv"
                        style={{ color: isPlay ? 'rgb(82, 255, 47)' : 'red' }}
                    >
                        <i className="fa-solid fa-backward" onClick={previous}></i>
                        {isPlay ?
                            <i className="fa-solid fa-stop" onClick={stop}></i>
                            :
                            <i className="fa-solid fa-play" onClick={play}></i>
                        }
                        <i className="fa-solid fa-forward" onClick={next}></i>
                    </div>

                    {/* Hiding Translation */}
                    <div id="hidingTranslationDiv">
                        {hideTranslation ?
                            <i className="fa-solid fa-eye-slash" onClick={toggleHideTranslation}></i>
                            :
                            <i className="fa-solid fa-eye" onClick={toggleHideTranslation}></i>
                        }
                    </div>

                    <TooltipKeyboardShortcuts mode="mediaPlayer" />

                    <div id="updateDiv">
                        {words[currentIndex] &&
                            <Update
                                wordToUpdate={words[currentIndex]}
                                words_localMemory={props.words_localMemory}
                                preferences_localMemory={props.preferences_localMemory}
                                userUuid={props.userUuid}
                                ifFixImagePosition={false}
                                updateWord={updateWord}
                                isUpdateOpen={isUpdateOpenHandler}
                            />
                        }
                    </div>

                </div>

            </div>
        </div>
    );
}

export default observer(MediaPlayer);
