import axios from 'axios';
import PreferencesModel from "../1_Models/PreferencesModel";
import urls from './Urls';

class Preferences_webAccess {

    public async get(): Promise<PreferencesModel> {
        const response = await axios.get<PreferencesModel>(urls.preferences.get);
        const preferences = response.data;
        return preferences;
    }

    public async update(preferences: PreferencesModel): Promise<PreferencesModel> {
        const response = await axios.patch<PreferencesModel>(urls.preferences.update, preferences);
        const newPreferences = response.data;
        return newPreferences;
    }
}

const preferences_webAccess = new Preferences_webAccess();

export default preferences_webAccess;