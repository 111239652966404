import { languagesAndVoices } from "./languagesAndVoices";

interface VoiceOptionsProps{
    language: string
} 

function VoiceOptions(props: VoiceOptionsProps): JSX.Element {

    const index = languagesAndVoices?.findIndex(item => item.language.value === props.language);

    return (
        <>
            {languagesAndVoices[index]?.voices.map((item, index) =>
                <option key={index} value={item.value}>
                    {item.name}
                </option>
            )}
        </>
    );
}

export default VoiceOptions;
