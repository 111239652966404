import { languageData } from "./languages";

function LanguageOptions_translation(): JSX.Element {

    return (
        <>
            {languageData?.data.languages.map((item, index) =>
                <option key={index} value={item.language}>
                    {item.name}
                </option>
            )}
        </>
    );
}

export default LanguageOptions_translation;
