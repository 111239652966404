import { languagesAndVoices } from "./languagesAndVoices";

function LanguageOptions_speech(): JSX.Element {

    return (
        <>
            {languagesAndVoices?.map((item, index) =>
                <option key={index} value={item.language.value}>
                    {item.language.name}
                </option>
            )}
        </>
    );
}

export default LanguageOptions_speech;
