import { useState } from "react";
import "./LanguageSelectorForTranslation.css";
import { useForm } from "react-hook-form";
import PreferencesModel from "../../../../1_Models/PreferencesModel";
import errorMessages from "../../../../5_Helpers/ErrorMessages";
import LanguageOptions_translation from "../Translation/LanguageOptions_translation";
import { Preferences_localMemory } from "../../../../3_LocalMemory/Preferences_localMemory";
import notify from "../../../../5_Helpers/NotifyMessages";
import { Tooltip } from "react-tooltip";

interface LanguageSelectorForTranslationProps {
    preferences_localMemory: Preferences_localMemory;
}

function LanguageSelectorForTranslation(props: LanguageSelectorForTranslationProps): JSX.Element {

    // Use Form --------------------------------
    const { register, handleSubmit } = useForm<PreferencesModel>({ defaultValues: props.preferences_localMemory.preferences });

    async function submit(preferences: PreferencesModel) {
        try {
            const oldPreferences = props.preferences_localMemory.preferences;

            let newPreferences = {
                ...oldPreferences,
                sourceLanguage: preferences.sourceLanguage,
                targetLanguage: preferences.targetLanguage
            };

            const message = await props.preferences_localMemory.update(newPreferences);
            notify.success(message);
        }
        catch (error: any) {
            errorMessages(error);
        }
    }

    // --------------------------------
    return (
        <div className="LanguageSelectorForTranslation">
            <h3>Translate + Add new word</h3>
            <form onChange={handleSubmit(submit)}>
                <p>Choose a word from the sentence to translate it</p>
                <table>
                    <tr>
                        <td>from</td>
                        <td>
                            <select
                                {...register("sourceLanguage")}>
                                <LanguageOptions_translation />
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>to</td>
                        <td>
                            <select
                                id="targetLanguage_select"
                                {...register("targetLanguage")}>
                                <LanguageOptions_translation />
                            </select>
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    );
}

export default LanguageSelectorForTranslation;
