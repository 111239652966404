import "./RateSpeechOptions.css";

function RateSpeechOptions(): JSX.Element {

    let start = 0.25;
    let end = 6;
    let options = [];

    for (let i = start; i <= end; i += 0.25) {
        options.push(i);
    }

    return (
        <>
            {
                options.map((item, index) => <option key={index} value={item}>{item}</option>)
            }
        </>
    );
}

export default RateSpeechOptions;
