import "./Home.css";
import { useMediaQuery } from "react-responsive";
import useWindowDimensions from "../../../5_Helpers/UseWindowDimensions";
import { useNavigate } from "react-router-dom";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import notify from "../../../5_Helpers/NotifyMessages";

function Home(): JSX.Element {

    // Height and Width -------------------------------
    const { height: heightOfWindow, width: widthOfWindow } = useWindowDimensions();
    let isMobile = useMediaQuery({ maxWidth: 840 });
    const [widthOfDiv, setWidthOfDiv] = useState<number>();

    useEffect(() => {
        let width = isMobile ? widthOfWindow - 36 : 805;
        setWidthOfDiv(width);
    }, [widthOfWindow]);

    // Rerouting by clicking div -------------------------------
    let navigate = useNavigate();
    function navigateHandler(args: SyntheticEvent): void {
        if (!(args.target as HTMLDivElement).matches("a")) {
            const routingTo = (args.currentTarget as HTMLDivElement).id;
            navigate("/words/" + routingTo);
        }
    }

    // Copying the email -------------------------------
    const emailRef = useRef(null);

    const handle_Email_CopyClick = () => {
        try {
            // Select the text inside the input element
            emailRef.current.select();
            // Copy the selected text
            document.execCommand('copy');
            // Deselect the text
            window.getSelection().removeAllRanges();

            notify.success('Email copied to clipboard!');
        } catch (err) {
            notify.error('Error copying text.');
        }
    };

    // Copying the website address -------------------------------
    const websiteAddressRef = useRef(null);

    const handle_WebsiteAddress_CopyClick = () => {
        try {
            websiteAddressRef.current.select();
            document.execCommand('copy');
            window.getSelection().removeAllRanges();

            notify.success('Website Address copied to clipboard!');
        } catch (err) {
            notify.error('Error copying text.');
        }
    };

    // -------------------------------
    return (
        <div className="Home"
            style={{
                height: heightOfWindow - 33,
                width: widthOfWindow
            }}>

            {/* 1-Home -------------------------------------------------------- */}
            <div id="homeDiv">

                <h1><span id="logo">W</span>ordStorage</h1>

                <h2>Learn any language of your choice easily and quickly!</h2>

                <p><i className="fa-solid fa-floppy-disk fa-spin"></i><span className="bold"> Save</span> the words you wish to remember, <span className="bold">memorize</span> them, and put your knowledge to the <span className="bold">test</span>.</p>
                <p><i className="fa-solid fa-image fa-spin"></i> Enhance your learning experience by attaching a <span className="bold">sentence</span> and a <span className="bold">picture</span> to each word.</p>
                <p><i className="fa-brands fa-airbnb fa-spin"></i> Benefit from automatic sentence generation through the assistance of <span className="bold">artificial intelligence</span>.</p>
                <p><i className="fa-solid fa-headphones fa-spin"></i> Immerse yourself in the language by <span className="bold">listening</span> to your selected words and sentences, with support available in <span className="bold">149 languages</span>.</p>
                <p>This comprehensive platform ensures a seamless and effective language learning journey. Start your linguistic adventure now!</p>

                <span id="websiteAddressSpan" onClick={handle_WebsiteAddress_CopyClick}>wordstorage.com</span>

                <input
                    ref={websiteAddressRef}
                    type="text"
                    value={"https://wordstorage.com"}
                    readOnly
                    style={{ position: 'absolute', left: '-9999px' }} // Hide the input element
                />

            </div>

            {/* 2-Words -------------------------------------------------------- */}
            <div id="wordsDiv">

                <h1><i className="fa-solid fa-lines-leaning"></i> My Words</h1>

                <h2>Your personal area is divided into three parts:</h2>

                <div id="list" style={{ width: widthOfDiv }} onClick={navigateHandler}>
                    <h3><i className="fa-solid fa-list fa-beat-fade"></i> List</h3>
                    <p>Organize your words by <span className="bold">adding</span>, <span className="bold">updating</span>, or <span className="bold">deleting</span> as necessary.</p>
                </div>

                <div id="media-player" style={{ width: widthOfDiv }} onClick={navigateHandler}>
                    <h3><i className="fa-solid fa-music fa-beat-fade"></i> Media Player</h3>
                    <p>Use a media player to <span className="bold">memorize</span> your words.</p>
                </div>

                <div id="test" style={{ width: widthOfDiv }} onClick={navigateHandler}>
                    <h3><i className="fa-solid fa-microscope fa-beat-fade"></i> Test</h3>
                    <p>For each word, give yourself a <span className="bold">score</span> from 1 to 3:</p>
                    <p id="threeScores">
                        <span style={{ color: 'red' }}>1 - don't know <i className="fa-regular fa-face-meh-blank"></i></span><br />
                        <span style={{ color: '#FFC439' }}>2 - know roughly <i className="fa-regular fa-face-meh"></i></span><br />
                        <span style={{ color: 'rgb(82, 255, 47)' }}>3 - know very well <i className="fa-regular fa-face-smile-beam"></i></span><br />
                    </p>
                </div>

            </div>

            {/* 3-Application -------------------------------------------------------- */}
            {/* <div id="applicationDiv">
                <NavLink to="https://median.co/share/xnarjy#androidphone" target="_blank">

                    <h1><i className="fa-solid fa-mobile-screen-button"></i> Application</h1>

                    <h2>The app is available for download on mobile devices.</h2>

                    <p>Available for Android and iOS</p>

                </NavLink>
            </div> */}

            {/* 3-Application -------------------------------------------------------- */}
            <div id="applicationDiv">

                <h1><i className="fa-solid fa-mobile-screen-button"></i> Application</h1>

                <h2>Application Download Instructions:</h2>

                <p>
                    1. Open your Chrome browser on either your computer or mobile device and click on the icon <i className="fa-solid fa-ellipsis-vertical"></i> in the top-left corner.
                    <br />
                    2. Select "<span id="installSpan">Install WordStorage</span>" from the menu.
                </p>

            </div>

            {/* 4-Payment -------------------------------------------------------- */}
            <div id="paymentDiv">
                <NavLink to="/payment">

                    <h1><i className="fa-solid fa-credit-card"></i> Payment</h1>
                    <h2>We provide a free trial along with affordable rates!</h2>
                    <p>Free <span className="bold">30-day</span> trial, <span className="bold">$2/month</span> thereafter.</p>
                    <p>You can cancel your subscription anytime.</p>

                </NavLink>
            </div>

            {/* 5-ContactUs -------------------------------------------------------- */}
            <div id="contactUsDiv" onClick={handle_Email_CopyClick}>

                <h1><i className="fa-solid fa-envelope"></i> Contact Us</h1>

                <h2>We would be happy to hear from you <i className="fa-solid fa-face-smile"></i></h2>

                <p id="email">Email: wordstorage.com@gmail.com</p>

                <input
                    ref={emailRef}
                    type="text"
                    value={"wordstorage.com@gmail.com"}
                    readOnly
                    style={{ position: 'absolute', left: '-9999px' }} // Hide the input element
                />

            </div>

            {/* 6-Rights -------------------------------------------------------- */}
            <div id="rightsDiv">

                <i className="fa-regular fa-copyright"></i>
                All rights reserved.

            </div>

        </div>
    );
}

export default Home;
