import { useForm } from "react-hook-form";
import "./SortAndSelect.css";
import { DisplaySettingsModel, InOrder, Picture, Sentence, SortBy } from "../../../../1_Models/DisplaySettingsModel";
import errorMessages from "../../../../5_Helpers/ErrorMessages";
import { Words_localMemory } from "../../../../3_LocalMemory/Words_localMemory";
import { observer } from "mobx-react";
import { Preferences_localMemory } from "../../../../3_LocalMemory/Preferences_localMemory";
import RateSpeechOptions from "../../../SharedArea/RateSpeechOptions/RateSpeechOptions";
import { User_localMemory } from "../../../../3_LocalMemory/User_localMemory";
import { Tooltip } from "react-tooltip";
import { useRef } from "react";

interface DisplaySettingsModel_and_rateSpeech {
    displaySettings: DisplaySettingsModel;
    rateSpeech: number;
}

interface SortAndSelectProps {
    words_localMemory: Words_localMemory;
    preferences_localMemory: Preferences_localMemory;
    user_localMemory: User_localMemory;
}

function SortAndSelect(props: SortAndSelectProps): JSX.Element {

    // Object to 'defaultValues' and 'displaySettings' -------------------------------------------
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    let displaySettingsModel_and_rateSpeech_default: DisplaySettingsModel_and_rateSpeech = {
        displaySettings: {
            sort: {
                sortBy: SortBy.Date,
                inOrder: InOrder.Descending,
            },
            select: {
                category: "",
                picture: Picture.All,
                sentence: Sentence.All,
                score: 0,
                range: {
                    from: 1,
                    to: props.words_localMemory.words.length
                },
                date: {
                    from: props.user_localMemory.user.date.slice(0, 10),
                    to: formattedDate
                }
            },
            search: ""
        },
        rateSpeech: props.preferences_localMemory.preferences?.rateSpeech
    };

    // Use Form --------------------------------------------------------------------------------------
    const { register, handleSubmit, reset, setValue } =
        useForm<DisplaySettingsModel_and_rateSpeech>({
            defaultValues:
                props.words_localMemory.displaySettingsModel != null ?
                    {
                        displaySettings: props.words_localMemory.displaySettingsModel,
                        rateSpeech: props.preferences_localMemory.preferences?.rateSpeech
                    }
                    :
                    displaySettingsModel_and_rateSpeech_default
        });

    async function submit(displaySettingsModel_and_rateSpeech: DisplaySettingsModel_and_rateSpeech) {
        if (displaySettingsModel_and_rateSpeech.rateSpeech != props.preferences_localMemory.preferences?.rateSpeech) {
            try {
                let preferences = {
                    ...props.preferences_localMemory.preferences,
                    rateSpeech: displaySettingsModel_and_rateSpeech.rateSpeech
                };
                await props.preferences_localMemory.update(preferences);
            }
            catch (error: any) {
                errorMessages(error);
            }
        }
        else {
            try {
                // Resetting the array so that the "sort" does not affect the "select" when selecting a range.
                if (
                    (displaySettingsModel_and_rateSpeech.displaySettings.select.range.from > 1 ||
                        displaySettingsModel_and_rateSpeech.displaySettings.select.range.to < props.words_localMemory.words.length) &&
                    !displaySettingsModel_and_rateSpeech.displaySettings.search
                ) {
                    let displayDefault = { ...displaySettingsModel_and_rateSpeech_default.displaySettings };
                    displayDefault.sort.inOrder = InOrder.Ascending;
                    await props.words_localMemory.displaySettings(displayDefault);
                }

                await props.words_localMemory.displaySettings(displaySettingsModel_and_rateSpeech.displaySettings);
                props.words_localMemory.updateCount();

                localStorage.setItem('displaySettings', JSON.stringify(displaySettingsModel_and_rateSpeech.displaySettings));
            }
            catch (error: any) {
                errorMessages(error);
            }
        }
    }

    // Reset --------------------------------------------------------------------------------------
    function resetSortAndSelect(): void {
        reset(displaySettingsModel_and_rateSpeech_default);
        props.words_localMemory.displaySettings(displaySettingsModel_and_rateSpeech_default.displaySettings);
        props.words_localMemory.updateCount();
        localStorage.removeItem("displaySettings");
    }

    // Select the text in the input field -----------------------------------------------------------
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select();
    };

    // Refresh -----------------------------------------------------------
    function refresh(): void {
        const displaySettings = JSON.parse(localStorage.getItem("displaySettings"));
        if (!displaySettings) return;

        let settings: DisplaySettingsModel_and_rateSpeech = {
            displaySettings: displaySettings,
            rateSpeech: props.preferences_localMemory.preferences?.rateSpeech
        };

        submit(settings);
    }

    // Clearing a search box by clicking on the label -------------------------------------------------
    const handleSearchLabelClick = () => {
        // Update input box
        setValue('displaySettings.search', '');

        // Focus on the input box
        const searchInput = document.getElementById('searchInput');
        searchInput?.focus();

        // Update "displaySettings" object
        let ds: DisplaySettingsModel_and_rateSpeech = {
            displaySettings: props.words_localMemory.displaySettingsModel,
            rateSpeech: props.preferences_localMemory.preferences?.rateSpeech
        }
        ds.displaySettings.search = '';
        submit(ds);
    };

    // --------------------------------------------------------------------------------------
    return (
        <div className="SortAndSelect">
            <form id="sortAndSelectForm" onChange={handleSubmit(submit)}>

                {/* 1 - Sort */}
                <div id="sortDiv">
                    <label>Sort</label>
                    <div>
                        <select {...register("displaySettings.sort.sortBy")}>
                            <option value={SortBy.Date}>Date</option>
                            <option value={SortBy.Alphabetically}>Alphabetically</option>
                            <option value={SortBy.Score}>Score</option>
                            <option value={SortBy.Category}>Category</option>
                            <option value={SortBy.Random}>Random</option>
                        </select>
                        <select {...register("displaySettings.sort.inOrder")}>
                            <option value={InOrder.Ascending}>Ascending</option>
                            <option value={InOrder.Descending}>Descending</option>
                        </select>
                    </div>
                </div>

                {/* 2 - Category */}
                <div id="categoryDiv">
                    <label> Category </label>
                    <select {...register("displaySettings.select.category")} >
                        <option value={""}>All</option>
                        {props.words_localMemory.categories?.map((c, index) => <option key={index} value={c}>{c}</option>)}
                    </select>
                </div>

                {/* 3 - Picture */}
                <div id="pictureDiv" className="selectDiv">
                    <label> Picture </label>
                    <select {...register("displaySettings.select.picture")}>
                        <option value={0}>All</option>
                        <option value={1}>Yes</option>
                        <option value={2}>No</option>
                    </select>
                </div>

                {/* 4 - Sentence */}
                <div id="sentenceDiv" className="selectDiv">
                    <label> Sentence </label>
                    <select {...register("displaySettings.select.sentence")}>
                        <option value={0}>All</option>
                        <option value={1}>Yes</option>
                        <option value={2}>No</option>
                    </select>
                </div>

                {/* 5 - Score */}
                <div id="scoreDiv" className="selectDiv">
                    <label>Score</label>
                    <select {...register("displaySettings.select.score")}>
                        <option value={0}>All</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                    </select>
                </div>

                {/* 6 - Date */}
                <div id="dateDiv" className="selectDiv">
                    <label>Date</label>
                    <div>
                        <input type="date" {...register("displaySettings.select.date.from")} />
                        -
                        <input type="date" {...register("displaySettings.select.date.to")} />
                    </div>
                </div>

                {/* 7 - Range */}
                <div id="rangeDiv">
                    <label>Range</label>
                    <div>
                        <input type="number" {...register("displaySettings.select.range.from")} onFocus={handleFocus}></input>
                        -
                        <input type="number" {...register("displaySettings.select.range.to")} onFocus={handleFocus}></input>
                    </div>
                </div>

                {/* 8 - Search */}
                <div id="searchDiv">
                    <label onClick={handleSearchLabelClick}>Search</label>
                    <input id='searchInput' type="text" {...register("displaySettings.search")} />
                </div>

                {/* 9 - TotalWords */}
                <div id="totalWordsDiv">
                    <label>Total Words</label>
                    <div>
                        <span id="totalWordsSpan" onClick={refresh}
                            data-tooltip-id="refresh" data-tooltip-content="Refresh"
                        >{props.words_localMemory.count}</span>
                        <span id="resetSpan" onClick={resetSortAndSelect}>Reset <i className="fa-solid fa-arrow-rotate-left"></i></span>
                    </div>
                    <Tooltip id="refresh" place='bottom' />
                </div>

                {/* 10 - Rate Speech */}
                <div id="speedDiv">
                    <label>Speed of Speech</label>
                    <div>
                        <select {...register("rateSpeech")}>
                            <RateSpeechOptions />
                        </select>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default observer(SortAndSelect);
